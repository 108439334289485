'use client';

import {
  AGENCY_AMOUNT_MAP,
  AGENCY_DISCOUNT_MAP,
  type AgencyAffiliateCode,
} from '@v2/types';
import { Badge, Button, ChevronLeft } from '@v2/ui';
import { cn } from '@v2/utils';
import { format } from 'date-fns';
import { useRouter } from 'next/navigation';
import { isValidElement } from 'react';
import { ContentContainer } from '../../components/ContentWrapper';

export function AffiliatePromoInfoView({
  status,
  startDate,
  endDate,
  code,
  type,
  amount,
  minOrderPrice,
  useLimit,
  usedTimes,
  currency,
  createdByUser,
  amountType,
  createdAt,
}: AgencyAffiliateCode) {
  const router = useRouter();
  return (
    <ContentContainer className="gap-y-5 md:gap-y-6">
      <Button
        variant="ghost"
        className="md:text-md items-center justify-start gap-x-2 p-0 text-xs font-semibold text-gray-500"
        onClick={() => router.back()}
      >
        <ChevronLeft size="20" /> All codes
      </Button>
      <h3 className="text-xl font-bold text-gray-800 md:text-2xl">Rewards</h3>

      <div className="grid grid-cols-2 gap-x-2 gap-y-5 md:grid-cols-3 md:gap-6 lg:grid-cols-4">
        <PromoItem title="Code" subtitle={code} className="col-span-2" />
        <PromoItem
          title="Type"
          subtitle={AGENCY_DISCOUNT_MAP[type]}
          className="md:col-span-2"
        />
        <PromoItem
          title="Amount"
          subtitle={`${amount}${AGENCY_AMOUNT_MAP[amountType]} ${currency || 'both'}`}
        />
        <PromoItem
          title="Status"
          subtitle={
            <Badge
              size="md"
              variant={status === 'active' ? 'success' : 'error'}
              className="w-max text-sm font-medium capitalize"
            >
              {status}
            </Badge>
          }
        />
        <PromoItem
          title="Start date"
          subtitle={
            startDate ? format(new Date(startDate), 'MMM dd, yyyy') : '-'
          }
        />
        <PromoItem
          title="End Date"
          subtitle={endDate ? format(new Date(endDate), 'MMM dd, yyyy') : '-'}
        />
        <PromoItem
          title="Minimum order amount"
          subtitle={minOrderPrice || '-'}
        />
        <PromoItem title="Use limit" subtitle={useLimit || '-'} />
        <PromoItem title="Used times" subtitle={usedTimes} />
        <PromoItem
          title="Created at"
          subtitle={endDate ? format(new Date(createdAt), 'MMM dd, yyyy') : '-'}
        />
        <PromoItem title="Created by" subtitle={createdByUser} />
      </div>
    </ContentContainer>
  );
}
function PromoItem({
  title,
  subtitle,
  className,
}: {
  title: string;
  className?: string;
  subtitle: string | number | React.ReactNode;
}) {
  return (
    <div className={cn('flex flex-col gap-y-1', className)}>
      <h4 className="text-sm font-medium text-gray-500"> {title}</h4>
      {isValidElement(subtitle) ? (
        subtitle
      ) : (
        <p className="text-md font-semibold text-gray-800">{subtitle}</p>
      )}
    </div>
  );
}
