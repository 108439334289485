'use client';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuTrigger,
} from '@v2/ui';
import { cn } from '@v2/utils';

import Image from 'next/image';
import { CURRENCY_LIST, useCurrencyDropdown } from './use-currency-dropdown';

interface Props {
  className?: string;
}

export const CurrencyDropdownIconMode = ({ className }: Props) => {
  const [selectedCurrency, setSelectedCurrency] = useCurrencyDropdown();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(`flex flex-row items-center`, className)}
      >
        <Image
          src={CURRENCY_LIST[selectedCurrency].flag}
          width={20}
          height={20}
          alt={CURRENCY_LIST[selectedCurrency].name}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[194px] bg-white" align="end">
        <DropdownMenuSub>
          {Object.keys(CURRENCY_LIST).map((key) => (
            <DropdownMenuItem
              key={key}
              className="flex cursor-pointer gap-3 hover:bg-gray-100"
              onSelect={() => {
                setSelectedCurrency(key);
              }}
            >
              <Image
                src={CURRENCY_LIST[key].flag}
                width={20}
                height={20}
                alt={CURRENCY_LIST[key].name}
              />
              <h4 className="text-base font-semibold">
                {CURRENCY_LIST[key].name}{' '}
                <span className="text-gray-500">
                  ({CURRENCY_LIST[key].slug})
                </span>
              </h4>
            </DropdownMenuItem>
          ))}
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
