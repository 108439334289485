'use client';

import { useAuthUserType } from '@hooks/use-auth-user-type';
import { sendVerificationCode } from '@v2/action';
import {
  AlertToast,
  Button,
  CheckCircle,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  Typography,
} from '@v2/ui';
import { cn } from '@v2/utils';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useCountdown } from 'usehooks-ts';
import { INPUT_OTP_SLOTS } from '../util';
import { ChooseANewPassword } from './ChooseANewPassword';

export function ConfirmPasswordIdentity() {
  const [value, setValue] = useState('');
  const searchParams = useSearchParams();
  const userType = useAuthUserType();

  const [hasVerified, setHasVerified] = useState(false);

  const email = searchParams!.get('email')!;

  const origEmail = decodeURIComponent(email);

  const handleVerifyCode = async () => {
    setHasVerified(true);
  };

  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 30,
    intervalMs: 1000,
  });

  useEffect(() => {
    startCountdown();
  }, []);

  const handleResetCountdown = async () => {
    sendVerificationCode({ email: origEmail, userType });

    toast.custom(
      (t) => (
        <AlertToast
          {...t}
          title="Success"
          content={'code has  been sent to your email.'}
          icon={<CheckCircle />}
        />
      ),
      { position: 'top-right' }
    );

    resetCountdown();
    startCountdown();
  };

  if (hasVerified) return <ChooseANewPassword code={value} email={origEmail} />;
  return (
    <>
      <div>
        <Typography
          variant="h1"
          size={'dsm'}
          className={cn(
            'mb-3 text-center',
            userType === 'agency' && 'text-white'
          )}
        >
          Let’s confirm your identity
        </Typography>
        <Typography
          size={'tmd'}
          className={cn(
            'text-center font-medium',
            userType === 'agency' ? 'text-gray-400' : 'text-gray-500'
          )}
        >
          Enter the security code we sent to your email.
        </Typography>
      </div>

      <div className="w-full">
        <InputOTP
          maxLength={INPUT_OTP_SLOTS}
          value={value}
          onChange={(value) => setValue(value)}
          render={({ slots }) => (
            <InputOTPGroup className="w-full">
              {slots.map((slot, index) => (
                <InputOTPSlot
                  key={index}
                  {...slot}
                  className={cn('w-full', userType === 'agency' && 'bg-white')}
                />
              ))}{' '}
            </InputOTPGroup>
          )}
        />
      </div>

      {/* BUTTON */}
      <div className="flex flex-col gap-y-3">
        <Button
          fullWidth
          size="xl"
          className="w-full"
          disabled={value.length !== INPUT_OTP_SLOTS}
          onClick={handleVerifyCode}
        >
          Continue
        </Button>

        <Button
          fullWidth
          variant="tertiary"
          size="xl"
          disabled={count !== 0}
          onClick={handleResetCountdown}
        >
          Resend code
          {count === 0 ? '' : ` in ${count}s`}
        </Button>
      </div>
    </>
  );
}
