'use client';

import { cn } from '@v2/utils';
import Link from 'next/link';

import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { v2Links } from '@utils/navigation/links';
import { typographyVariants } from '@v2/ui';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

export const AffiliateHeader = ({}) => {
  const isTablet = useBetterMediaQuery('(min-width: 768px)');
  const pathname = usePathname();

  const isAffiliate = pathname === '/ambassador-program';

  return (
    <header className="relative z-10 mx-auto mb-10 mt-0 flex h-[72px] w-full max-w-7xl items-center justify-between px-4 md:mb-20 md:h-24 xl:px-0">
      {isTablet ? (
        <Image
          src="/logo-text-white.svg"
          alt="Elite Sports Tours"
          width={256}
          height={40}
        />
      ) : (
        <Image
          src={'/logo-white.svg'}
          alt="Background Image"
          width={42.5}
          height={40}
        />
      )}

      <div className={`flex items-center gap-6`}>
        <Link
          href={
            isAffiliate ? v2Links.login : `${v2Links.login}?userType=agency`
          }
          prefetch={false}
        >
          Log in
        </Link>
        <Link
          href={isAffiliate ? v2Links.ambassadorSignup : v2Links.partnerSignup}
          prefetch={false}
        >
          <button
            className={cn(
              `${typographyVariants({ size: isTablet ? 'tmd' : 'tsm' })} h-9 w-[78px] rounded-lg border-transparent bg-slate-500/25 text-white backdrop-blur-sm md:h-12 md:w-24`
            )}
          >
            Sign up
          </button>
        </Link>
      </div>
    </header>
  );
};
