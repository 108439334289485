import { ArrowDown, ArrowUp } from '@v2/ui';
import { cn } from '@v2/utils';
import React from 'react';

interface SortIndicatorProps {
  isSorted: boolean;
  isSortedDesc: boolean | undefined;
  className?: string;
}

const SortIndicator: React.FC<SortIndicatorProps> = ({
  isSorted,
  isSortedDesc,
  className,
}) => {
  const baseClassName = 'ml-2 text-gray-500';
  if (!isSorted) return;
  return isSortedDesc ? (
    <ArrowDown className={cn(baseClassName, className)} size="16" />
  ) : (
    <ArrowUp className={cn(baseClassName, className)} size="16" />
  );
};

export default SortIndicator;
