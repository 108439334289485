import routes from '@config/routes';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import { serverFetcher } from '@instance';
import { BaseProperty } from '@interfaces/expedia/property';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { useEffect, useMemo } from 'react';
import { useBuildPackagesStore } from 'src/stores/build-package-store';
import useSWR from 'swr';

const DEFAULT_MAX_PRICE = 6500;

export function useGetHotels() {
  const distance = useSearchHotelStore(
    (state) => state.hotelFilters.distance[0]
  );

  const sortBy = useSearchHotelStore((state) => state.hotelFilters.sortBy);

  const { isBundle, minTicketPrice } = useBuildPackageBundle();

  const query = useSearchHotelStore((state) => state.hotelFilters.query);
  const ratings = useSearchHotelStore((state) => state.hotelFilters.rating);
  const priceRange = useSearchHotelStore(
    (state) => state.hotelFilters.pricePerTraveler
  );
  const setHotelFilter = useSearchHotelStore((state) => state.setHotelFilters);

  const featureHotel = useSearchHotelStore(
    (state) => state.hotelFilters.featureHotel
  );
  const base = useBuildPackagesStore((state) => ({
    startDate: state.hotelStartDate,
    endDate: state.hotelEndDate,
    location: state.location,
    roomConfigurations: state.roomConfigurations,
  }));

  const { data, isValidating } = useSWR<{
    error: [];
    properties: BaseProperty[];
  }>(
    routes.propertySearch,
    (url) =>
      serverFetcher(url, {
        body: {
          base,
          filters: {
            distance,
            sortBy,
          },
          options: {
            // A.S:Property not currently used when filtering properties
            requiredCurrency: 'USD',
            maxResult: 100,
            isBundle,
          },
        },
      }),
    { suspense: true, revalidateIfStale: false, revalidateOnFocus: false }
  );

  const maxHotelPrice = useMemo(() => {
    const properties = data?.properties;
    if (properties?.length === 0) return DEFAULT_MAX_PRICE;
    let mp =
      properties?.reduce((max, hot) => {
        if (hot.price > max) max = hot.price;
        return max;
      }, Number.MIN_SAFE_INTEGER) ?? 0;

    if (mp === Number.MIN_SAFE_INTEGER) mp = 0;

    const addOnPrice = isBundle ? minTicketPrice : 0;
    if (mp + addOnPrice > DEFAULT_MAX_PRICE) {
      return mp + minTicketPrice;
    }

    return DEFAULT_MAX_PRICE;
  }, [data, isBundle, minTicketPrice]);

  useEffect(() => {
    if (maxHotelPrice > DEFAULT_MAX_PRICE) {
      setHotelFilter({ pricePerTraveler: [0, maxHotelPrice] });
      useSearchHotelStore.setState({
        hasPricePerTravelerChanged: false,
      });
    }
  }, [maxHotelPrice, DEFAULT_MAX_PRICE]);

  const listFiltered = useMemo(() => {
    if (!data?.properties?.length) return [];

    let filteredHotels = [...data?.properties];

    if (query?.length) {
      filteredHotels = filteredHotels?.filter((property) =>
        property.name.toLowerCase().includes(query.toLowerCase())
      );
    }
    const [min, max] = priceRange;

    filteredHotels = filteredHotels?.filter((property) => {
      const basePrice = isBundle
        ? property.price + minTicketPrice
        : property.price;

      return basePrice >= min && basePrice <= max;
    });

    if (ratings?.length) {
      const intRatings = ratings.map((rate) => parseInt(rate, 10));
      filteredHotels = filteredHotels?.filter((property) =>
        intRatings.includes(property.rating)
      );
    }

    if (featureHotel?.length) {
      filteredHotels = filteredHotels?.filter((property) => {
        return featureHotel.every((feature) => {
          return property.amenities
            .toLowerCase()
            .includes(feature.toLowerCase());
        });
      });
    }

    return filteredHotels;
  }, [
    data?.properties,
    query,
    ratings,
    priceRange,
    featureHotel,
    isBundle,
    minTicketPrice,
  ]);

  return { data: listFiltered, isValidating, maxHotelPrice };
}
