import { Typography } from '@v2/ui';
import { cn } from '@v2/utils';
import { differenceInDays, format } from 'date-fns';
import Image from 'next/image';
import pluralize from 'pluralize';
import { ReactNode } from 'react';

type Props = {
  id: number;
  title: string;
  date: string | ReactNode;
  time?: string;
  occursAt: string;
  location: string;
  topPick?: boolean;
  className?: string;
  bakcgroundImageUrl?: string;
};

// TODO SEE USE FOR  ASPECT RATIO

export function EventCard({
  date,
  time,
  id,
  location,
  title,
  topPick,
  className,
  bakcgroundImageUrl = '/default-event-image.svg',
  occursAt,
}: Props) {
  const remainingDaysForTheEvent = differenceInDays(
    new Date(occursAt.split('T')[0]),
    new Date(format(new Date(), 'yyyy-MM-dd'))
  );

  return (
    <div className={cn('flex w-[302px] flex-col', className)}>
      <div className="mb-3 h-40 w-full rounded-lg bg-cover">
        <Image
          src={bakcgroundImageUrl}
          alt={title}
          width="0"
          height="0"
          sizes="410px"
          loading="lazy"
          className="h-[160px] w-full rounded-lg object-cover object-center"
        />
      </div>
      <div className="w-full max-w-64">
        <Typography variant="h3" size="tmd" className="mb-2 font-semibold">
          {title}
        </Typography>
        <Typography size="tsm" className="font-medium text-gray-500">
          {remainingDaysForTheEvent < 5 ? (
            <span className="font-semibold text-primary-500">
              in {remainingDaysForTheEvent}{' '}
              {pluralize('day', remainingDaysForTheEvent)}
            </span>
          ) : (
            date
          )}
          {time && <> &middot; {time}</>}
          <br /> {location}
        </Typography>
      </div>
    </div>
  );
}
