import tevoRoutes from '@config/tevoRoutes';
import categoriesJSON from '@requests/mocks/categories.json';
import leaguesData from '@requests/mocks/leagues-data.json';
import { leagues } from '@utils/config/leagues';
import { testSign } from '@utils/x-signature';

import routes from '@config/routes';
import instance, { serverFetcher } from '@instance';
import { IVenue, LeagueEvent, TicketEvoError } from '@interfaces/APITravel';
import { FollowList } from '@interfaces/auth';
import devConsole from '@utils/developer-console';
import flatten from 'lodash/flatten';

type Categories = typeof categoriesJSON;

const getLeaguesData = async (mock?: boolean) => {
  try {
    // /* mock json response */
    if (mock) return leaguesData;

    /* get all categories */
    const categories: Categories = await testSign.getResponse(
      tevoRoutes.categories
    );
    const sorted = categories.categories.filter((i) =>
      leagues.includes(i.name.toLowerCase())
    );

    const events = await Promise.allSettled(
      sorted.map((league) =>
        testSign.getResponse(
          tevoRoutes.events({
            category_id: league.id,
          })
        )
      )
    );

    return sorted.map((data, index) => {
      // @ts-ignore
      const { value, status } = events[index];

      return {
        ...data,
        events: status !== 'rejected' ? value : [],
      };
    });
  } catch (e) {
    return e;
  }
};

export default getLeaguesData;

export const getCarouselEvents = async (follow: FollowList) => {
  return Promise.all<Array<LeagueEvent>>(
    follow.map(({ id, type }) =>
      instance
        .api(routes.getFollowedEvents, {
          body: JSON.stringify({ id, type }),
        })
        .then((res) => res.json())
    )
  )
    .catch()
    .then((all) => flatten<LeagueEvent>(all))
    .then((arr) => {
      const t = {};
      arr.forEach((item) => {
        t[item.id] = item;
      });
      return Object.values(t) as LeagueEvent[];
    })
    .then((array) =>
      array.sort(
        ({ occurs_at: a }, { occurs_at: b }) =>
          new Date(a).getTime() - new Date(b).getTime()
      )
    );
};

export const getEvent = async (
  eventId: number | string
): Promise<LeagueEvent | TicketEvoError> => {
  return testSign.getResponse(tevoRoutes.event(eventId));
};
export const getVenue = async (
  venueId: number | string
): Promise<IVenue | TicketEvoError> => {
  return testSign.getResponse(tevoRoutes.venues(venueId));
};

export const getListings = async (eventId: string | number): Promise<any> => {
  return testSign.getResponse(tevoRoutes.listings(eventId));
};

const postFollowEvent = async (favoriteId, type, name, token) => {
  try {
    return await serverFetcher(routes.favorite, {
      method: 'POST',
      body: JSON.stringify({ favoriteId, type, name }),
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`postFollowEvent - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

const deleteFollowEvent = async (favoriteId, type, token) => {
  try {
    return await serverFetcher(routes.favorite, {
      method: 'DELETE',
      body: JSON.stringify({ favoriteId, type }),
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`deleteFollowEvent - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export { deleteFollowEvent, postFollowEvent };
