'use client';
import { Tabs, TabsContent, TabsList, TabsTrigger, Typography } from '@v2/ui';
import { useState } from 'react';

import { Favourite } from '@interfaces/events';

import { LeagueEvent } from '@interfaces/APITravel';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { EventItemCard } from './EventItem';
import { TeamsSection } from './TeamsSection';

type EventType = 'events' | 'teams';

type Props = {
  followedEvents: LeagueEvent[];
  follow?: Favourite[];
};

export function EventsTeamsList({ follow, followedEvents }: Props) {
  const [tab, setTab] = useState<EventType>('events');
  return (
    <div className="w-full">
      <Tabs value={tab} onValueChange={(type: EventType) => setTab(type)}>
        <TabsList variant="outline">
          <TabsTrigger variant="outline" value="events">
            Events
          </TabsTrigger>
          <TabsTrigger variant="outline" value="teams">
            Teams
          </TabsTrigger>
        </TabsList>
        <TabsContent value="events" className="w-full">
          <EventsList list={followedEvents} />
        </TabsContent>
        <TabsContent value="teams">
          <TeamsSection list={follow} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

function EventsList({ list }: { list: LeagueEvent[] }) {
  const [currentList, setCurrentList] = useState(list);
  const { update } = useSession();

  const router = useRouter();

  const isListEmpty = list.length === 0;

  if (isListEmpty) return <Placeholder type="events" />;

  return (
    <ul className="w-full">
      {currentList.map((item, index) => (
        <EventItemCard
          {...item}
          key={item.id}
          isItemFirst={index === 0}
          isItemlast={index === currentList.length}
        />
      ))}
    </ul>
  );
}

export function Placeholder({ type }: { type: string }) {
  return (
    <Typography
      size="tsm"
      className="text-center font-medium text-gray-500 md:text-start"
    >
      No {type} saved. Hover over events you’re interested in and tap the star
      icon.
    </Typography>
  );
}
