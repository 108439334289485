'use client';

import {
  Button,
  Close,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FilterFunnel01,
  typographyVariants,
} from '@v2/ui';

import { useState } from 'react';

import { cn } from '@v2/utils';

import {
  SearchHotelState,
  useSearchHotelStore,
} from '@store/search-hotel-store';
import { useFormik } from 'formik';
import { useMediaQuery } from 'usehooks-ts';
import { invalidatePropertySearch } from '../../util';
import {
  DistanceToVenueField,
  HotelFeatureField,
  PriceTravelerRangeField,
  QueryField,
  RatingField,
} from '../Filters';

export default function HotelMapFilterDialog() {
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery('(min-width: 1024px)');

  const setHotelFilters = useSearchHotelStore((state) => state.setHotelFilters);
  const setHotelRange = useSearchHotelStore((state) => state.setHotelRange);
  const hotelFilters = useSearchHotelStore((state) => state.hotelFilters);
  const formik = useFormik<SearchHotelState['hotelFilters']>({
    initialValues: {
      ...hotelFilters,
    },
    onSubmit: (val) => {
      const changedFields: Partial<SearchHotelState['hotelFilters']> = {};

      // Check if any of the fields have changed
      Object.keys(val).forEach((key) => {
        if (val[key] !== hotelFilters[key]) {
          changedFields[key] = val[key];
        }
      });

      const { pricePerTraveler, distance, ...restFilter } = changedFields;

      // Now 'changedFields'  only the fields that have changed

      setOpen(false);
      setHotelFilters({ ...restFilter });

      if (pricePerTraveler) {
        setHotelRange({ pricePerTraveler });
      }

      if (distance) {
        setHotelRange({ distance });
      }
      invalidatePropertySearch();
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {matches ? (
          <Button variant="secondary">Filters</Button>
        ) : (
          <FilterFunnel01 />
        )}
      </DialogTrigger>
      <DialogContent
        showCloseButton={false}
        className="flex h-screen w-full flex-col rounded-none p-0 md:grid md:h-auto md:rounded-xl"
      >
        <DialogHeader className="border-b border-gray-300">
          <div className="flex flex-row-reverse items-center justify-end p-4 md:flex-row md:justify-between md:p-6">
            <DialogTitle
              className={cn(
                typographyVariants({ size: matches ? 'tmd' : 'tsm' }),
                'font-bold md:font-semibold'
              )}
            >
              Filters
            </DialogTitle>
            <DialogClose className="m-0">
              <Close className="text-gray-500" />
            </DialogClose>
          </div>
        </DialogHeader>

        {matches && (
          <div
            className="absolute bottom-[61px] left-0 right-0 z-10 h-12 w-full"
            style={{
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
            }}
          />
        )}
        <form onSubmit={formik.handleSubmit} className="flex h-full flex-col">
          <div className="relative flex-1 space-y-8 overflow-y-scroll p-4 md:max-h-[500px] md:p-6">
            <QueryField
              value={formik.values.query}
              label="Property name"
              onValueChange={(value) => formik.setFieldValue('query', value)}
            />

            <RatingField
              value={formik.values.rating}
              onValueChange={(val) => formik.setFieldValue('rating', val)}
            />

            <HotelFeatureField
              value={formik.values.featureHotel}
              onValueChange={(val) => formik.setFieldValue('featureHotel', val)}
            />

            <PriceTravelerRangeField
              value={formik.values.pricePerTraveler}
              onValueChange={(val) =>
                formik.setFieldValue('pricePerTraveler', val)
              }
            />

            <DistanceToVenueField
              value={formik.values.distance}
              onValueChange={(val) => formik.setFieldValue('distance', val)}
              className="pb-5"
            />
          </div>
          <DialogFooter className="w-full flex-row justify-start gap-x-3 p-4 pt-0 md:gap-y-0">
            <Button
              className="w-full"
              variant="secondary"
              type="button"
              onClick={() => formik.resetForm()}
            >
              Clear
            </Button>
            <Button className="w-full" type="submit">
              Apply
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
