import {
  getAgencyMe,
  getAgencyOrders,
} from '@requests/get-pages-content/agency';
import { Skeleton } from '@v2/ui';
import { Suspense } from 'react';
import { ContentContainer } from '../../components/ContentWrapper';
import { ReportBarChart, ReportLineChart } from './components';

interface Props {
  accessToken: string;
}

export async function ReportsView(props: Props) {
  const { accessToken } = props;

  return (
    <ContentContainer className="gap-y-6 md:gap-y-8">
      <h2 className="text-2xl font-bold text-gray-800 md:text-4xl">Reports</h2>

      <div className="grid grid-cols-1 gap-3 md:gap-4 lg:grid-cols-2">
        <Suspense fallback={<Placeholder />}>
          <OrderReports accessToken={accessToken} />
        </Suspense>
      </div>
    </ContentContainer>
  );
}

async function OrderReports({ accessToken }: Props) {
  const agencyDetails = await getAgencyMe(accessToken);
  const data = await getAgencyOrders({
    agencyId: agencyDetails.id,
    token: accessToken,
  });

  return (
    <>
      <ReportBarChart {...data} />
      <ReportLineChart {...data} type="orders" label="Orders created" />
      <ReportLineChart {...data} type="bookings" label="Hotel bookings" />
      <ReportLineChart {...data} type="tickets" label="Tickets sold" />
    </>
  );
}

function Placeholder() {
  return (
    <>
      <Skeleton className="h-[342px] w-full rounded-xl" />
      <Skeleton className="h-[342px] w-full rounded-xl" />
      <Skeleton className="h-[342px] w-full rounded-xl" />
      <Skeleton className="h-[342px] w-full rounded-xl" />
    </>
  );
}
