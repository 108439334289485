'use client';

import routes from '@config/routes';
import instance from '@instance';
import { AlertToast, AlertTriangle, Button, CheckCircle, Input } from '@v2/ui';
import { useState } from 'react';
import toast from 'react-hot-toast';

type Tag = 'Newsletter' | 'Concerts';

type Props = {
  tag?: Tag;
};

export function NewsLetterCta({ tag = 'Newsletter' }: Props) {
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await instance.server(routes.addTagToUser, {
        body: JSON.stringify({
          email: email.toLowerCase(),
          tag,
        }),
      });
      if (response.ok) {
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Success"
              content={'Email subscribed'}
              icon={<CheckCircle />}
            />
          ),
          { position: 'top-right' }
        );

        setEmail('');
      } else {
        const { message } = await response.json();

        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Oops, something went wrong"
              content={message || 'Please try again in a minute.'}
              icon={<AlertTriangle />}
            />
          ),
          { position: 'top-right' }
        );
      }
    } catch (e: any) {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    } finally {
      setLoading(false);
    }

    return;
  };

  return (
    <div className="mx-auto py-10  bg-gray-900 p-10 px-4 text-center md:px-0 md:py-20">
      <h2 className="mb-4 md:mb-5 text-2xl md:text-4xl font-bold text-white">
        Sign up for our newsletter
      </h2>
      <p className="text-md md:text-lg font-medium text-gray-400">
        Never miss out on hearing about our new trips when they release!
      </p>
      <form
        className="mx-auto mt-8 flex items-stretch justify-center lg:mt-10 lg:items-center"
        onSubmit={handleSubmit}
      >
        <div className="w-auto lg:min-w-80">
          <Input
            extent="md"
            name="email"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="rounded-r-none border-r-0 h-[44px]"
            placeholder="Enter your email address"
          />
        </div>
        <Button type="submit" className="rounded-l-none border-l-0">
          Sign up
        </Button>
      </form>
    </div>
  );
}
