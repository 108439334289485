import { TextLink, Typography, typographyVariants } from '@v2/ui';
import { cn } from '@v2/utils/cn';
import { PropsWithChildren } from 'react';

export function Paragraph({
  children,
  className,
}: PropsWithChildren & { className?: string }) {
  return (
    <Typography
      size="tmd"
      className={cn('font-normal text-gray-800', className)}
    >
      {children}
    </Typography>
  );
}
export function ListItem({
  children,
  className,
}: PropsWithChildren & { className?: string }) {
  return (
    <li
      className={cn(
        typographyVariants({ size: 'tmd' }),
        'font-normal text-gray-800',
        className
      )}
    >
      {children}
    </li>
  );
}

export function Heading({ children }: PropsWithChildren) {
  return (
    <Typography size="dxs" className="font-bold">
      {children}
    </Typography>
  );
}
export function SubHeading({ children }: PropsWithChildren) {
  return (
    <Typography size="txl" className="font-bold">
      {children}
    </Typography>
  );
}
export function SmallHeading({ children }: PropsWithChildren) {
  return (
    <Typography size="tmd" className="font-bold">
      {children}
    </Typography>
  );
}
