export const SELECTED_FLIGHTS_STORAGE = '_sfs';
export const SELECTED_HOTEL_STORAGE = '_shs';
export const SELECTED_TICKET_STORAGE = '_sts';
export const SELECTED_ROOMS_STORAGE = '_srs';
export const PACKAGE_STORAGE = '_pgk';
export const EVENT_STORAGE = '_ev';
export const FLIGHT_ORDER_DATA = '_fod';
export const HOTEL_ORDER_DATA = '_hod';
export const HOTEL_LIST_ROOMS = '_lrf';
export const CHECKOUT_DATA = '_ckd';
export const TOTAL_PRICE = '_tpr';
export const SOCIAL_LOGIN_REDIRECT = '_slr';
export const MIN_PRICES = '_min';
export const UUID_STORAGE = '_uuid';
export const DISCOUNT_DATA = '_dsd';
export const CHECKOUT_START_DATETIME = '_cst';

// keys should to be cleared after closing / processed order
export const DIRTY_DATA_KEYS = [
  SELECTED_FLIGHTS_STORAGE,
  SELECTED_HOTEL_STORAGE,
  SELECTED_TICKET_STORAGE,
  SELECTED_ROOMS_STORAGE,
  PACKAGE_STORAGE,
  EVENT_STORAGE,
  FLIGHT_ORDER_DATA,
  HOTEL_ORDER_DATA,
  CHECKOUT_DATA,
  TOTAL_PRICE,
  SOCIAL_LOGIN_REDIRECT,
  MIN_PRICES,
  DISCOUNT_DATA,
];
