'use client';

import {
  getAgencyDetailsOverview,
  getAgencyPerformance,
} from '@requests/get-pages-content/agency';
import { USER_ROLE } from '@utils/auth/roles';
import { OrdersOverview } from './OrderReviewContent';

export async function TopOverview(props: {
  agencyId: number;
  accessToken: string;
  userRole: USER_ROLE;
}) {
  const { agencyId, accessToken: token, userRole } = props;

  const data = await getAgencyPerformance({
    token,
    agencyId,
  });

  const details = await getAgencyDetailsOverview({
    token,
    agencyId: agencyId,
  });

  if (typeof data === 'string' && data == 'No active agency found.')
    return null;

  return (
    <div>
      <h2 className="mb-6 text-2xl font-bold text-gray-800 md:mb-10 md:text-4xl">
        {userRole === USER_ROLE.superAdmin ? details.businessName : 'Overview'}
      </h2>
      <OrdersOverview {...data} agencyId={agencyId} />
    </div>
  );
}
