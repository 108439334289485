import { listSorterOption } from '@config/index';
import {
  Check,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@v2/ui';

import { cn } from '@v2/utils';
type Props = {
  children: React.ReactNode;
  value: string;
  onSelect: (val: string) => void;
  className?: string;
};

export function SortByDropdown({
  children,
  onSelect,
  value,
  className,
}: Props) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        className={cn('min-w-[250px]', className)}
        align="start"
      >
        {listSorterOption.map((item) => (
          <DropdownMenuItem
            textValue={item.value}
            key={item.value}
            className="justify-between"
            onSelect={() => onSelect(item.value)}
          >
            {item.name}
            {item.value === value && <Check />}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
