import { getAgencyMe } from '@requests/get-pages-content/agency';
import getUserSession from '@utils/auth/get-user-session';
import { v2Links } from '@utils/navigation/links';
import { redirect } from 'next/navigation';
import { Overview } from './Overview';

export const OverviewWrapper = async () => {
  const user = await getUserSession();

  const accessToken = user?.token?.accessToken;

  if (!accessToken) return redirect(`${v2Links.login}?userType=agency`);

  const agencyDetails = await getAgencyMe(user?.token?.accessToken);

  return (
    <Overview
      accessToken={accessToken}
      agencyId={agencyDetails.id}
      userRole={user.role}
    />
  );
};
