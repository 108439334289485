import { jwtDecode } from 'jwt-decode';

interface IJwtDecoded {
  exp: number;
}

const isValidToken = (token: string | undefined) => {
  if (!token) {
    return false;
  }

  const decodedToken: IJwtDecoded = jwtDecode(token);

  const currentTime = Math.round(Date.now() / 1000);
  return decodedToken.exp > currentTime;
};

export default isValidToken;
