'use client';

import { useAuthUserType } from '@hooks/use-auth-user-type';
import { Typography } from '@v2/ui';
import { cn } from '@v2/utils';
import ClientContent from './ClientContent';

export function ConfirmIdentity() {
  const userType = useAuthUserType();
  return (
    <>
      <div>
        <Typography
          variant="h1"
          size={'dsm'}
          className={cn(
            'mb-3 text-center',
            userType === 'agency' && 'text-white'
          )}
        >
          Let’s confirm your identity
        </Typography>
        <Typography
          size={'tmd'}
          className={cn(
            'text-center font-medium',
            userType === 'agency' ? 'text-gray-400' : 'text-gray-500'
          )}
        >
          Enter the security code we sent to your email.
        </Typography>
      </div>

      <ClientContent />
    </>
  );
}
