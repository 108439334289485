import { differenceInDays, differenceInMonths } from 'date-fns';

export function getRelativeDateCategory(occurs_at) {
  const today = new Date();
  const eventDate = new Date(occurs_at.split('T')[0]);

  const remainingDays = differenceInDays(eventDate, today);
  const remainingMonths = differenceInMonths(eventDate, today);

  if (remainingDays === 0) {
    return 'Today';
  } else if (remainingDays === 1) {
    return 'Tomorrow';
  } else if (remainingDays >= 2 && remainingDays <= 6) {
    return 'In 2-6 days';
  } else if (remainingDays > 6 && remainingDays <= 28) {
    return 'In 1-4 weeks';
  } else if (remainingMonths >= 1 && remainingMonths <= 6) {
    return 'In 1-6 months';
  } else if (remainingMonths > 6) {
    return 'In +6 months';
  }
}
