import Image from 'next/image';
import React from 'react';

import { cn } from '@v2/utils';
import { Typography } from '@v2/ui';
type Props = {
  className?: string;
};

export function BuyPackageFooter({ className }: Props) {
  return (
    <div
      className={cn(
        'flex w-full flex-col items-start justify-between gap-y-4 px-4 py-8 md:mx-auto md:max-w-[1300px] md:flex-row md:items-center md:gap-y-0 md:px-20',
        className
      )}
    >
      <Image
        src="/logo-black-footer.svg"
        width={201}
        height={31.9}
        alt="Elite sports tour logo"
      />

      <Typography size="tsm" className="font-medium text-gray-500">
        Copyright © {new Date().getFullYear()} Elite Sports Tours Inc.
      </Typography>
    </div>
  );
}
