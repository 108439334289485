import { useEffect } from 'react';
import { useAnimate } from 'framer-motion';

export const getPasswordStrength = (password) => {
  const lengthRegex = /^.{8,24}$/;
  const letterNumberRegex = /^(?=.*[a-zA-Z])(?=.*\d)/;
  const specialCharRegex = /[~#@$%&!*?^-]/;

  let strength = 0;
  if (lengthRegex.test(password)) strength++;
  if (letterNumberRegex.test(password)) strength++;
  if (specialCharRegex.test(password)) strength++;

  return strength;
};

const usePasswordStrength = (password) => {
  const [scope, animate] = useAnimate();

  const animationVariants = {
    Average: { width: '66%', backgroundColor: '#F79009' },
    Strong: { width: '100%', backgroundColor: '#17B26A' },
  };

  const strength = getPasswordStrength(password);

  const strengthCategory =
    strength === 3 ? 'Strong' : strength >= 2 ? 'Average' : 'Weak';

  useEffect(() => {
    if (!scope.current) return;

    if (password.length > 0) {
      animate(scope.current, animationVariants[strengthCategory]);
    } else {
      animate(scope.current, { width: '0%', backgroundColor: '#E8EAEE' });
    }
  }, [password]);

  return { scope, strengthCategory };
};

export default usePasswordStrength;
