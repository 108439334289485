import { LeagueEvent } from '@interfaces/APITravel';
import { LoggedInUserProps } from '@interfaces/auth';
import { Favourite } from '@interfaces/events';
import { getCarouselEvents } from '@requests/get-pages-content/events';
import getUserSession from '@utils/auth/get-user-session';
import { Skeleton, Typography } from '@v2/ui';
import { Suspense } from 'react';
import { ContentContainer } from '../../components/ContentWrapper';
import { EventsTeamsList } from './sections/EventsTeamsList';
import { MyFeed } from './sections/MyFeed';

export async function Favorites({ user }: { user: LoggedInUserProps }) {
  return (
    <ContentContainer className="gap-y-10 md:gap-y-8">
      <div>
        <h2 className="mb-3 text-2xl font-bold text-gray-800 md:text-4xl md:-tracking-0.72">
          Favorites
        </h2>

        <Typography size="tmd" className="text-gray-500">
          Manage your saved events and teams.
        </Typography>
      </div>
      {/* <MyFeed /> */}
      <Suspense fallback={<EventsTeamSkeleton />}>
        <EventsTeamWrapper />
      </Suspense>
    </ContentContainer>
  );
}

export function EventsTeamSkeleton() {
  return (
    <div className="flex flex-col gap-y-6">
      <Skeleton className="h-8 w-[100px]" />

      <div className="flex flex-col">
        {[0, 1, 2, 3, 4].map((item) => (
          <Skeleton className="h-[90px] w-full" key={item.toString()} />
        ))}
      </div>
    </div>
  );
}

export async function EventsTeamWrapper() {
  const user = await getUserSession();

  let followedEvents: LeagueEvent[] = [];
  let follow: Favourite[] = [];

  if (user?.follow?.length) {
    followedEvents = await getCarouselEvents(user?.follow);

    follow = user.follow.map(({ id, name, type }) => {
      return {
        id,
        favoriteId: id,
        name,
        type,
      };
    });
  }

  return <EventsTeamsList follow={follow} followedEvents={followedEvents} />;
}
