import { EVENT_STORAGE } from '@config/storageKeys';
import tevoRoutes from '@config/tevoRoutes';
import useStorage from '@hooks/useStorage';
import { apiFetcher } from '@instance';
import { LeagueEvent, TicketEvoError } from '@interfaces/APITravel';
import { TicketsResponse } from '@interfaces/ticketevolution/tikets';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

export type EventLoaderResponse = [
  LeagueEvent | TicketEvoError | null,
  TicketsResponse | null,
];

const useEventLoader = (id: string | null = null, needTickets?: boolean) => {
  const storage = useStorage();
  const [eventData, setEventData] = useState<EventLoaderResponse>([null, null]);
  const [eventId, setEventId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEventId(id || storage.getItem(EVENT_STORAGE));
  }, [id, storage]);

  const { data, error, isValidating } = useSWR<EventLoaderResponse>(
    eventId && tevoRoutes.getEventProxy(eventId, needTickets),
    apiFetcher,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  useEffect(() => {
    setLoading(!data && !error);
    setLoading(isValidating);
  }, [data, error, isValidating]);

  useEffect(() => {
    if (data && !error) {
      setEventData(data);
    }
  }, [data, error]);

  const [event, tickets] = eventData || [];

  return { event, tickets, loading };
};

export default useEventLoader;
