'use client';

import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';

import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { USER_ROLE } from '@utils/auth/roles';
import { DataTablePagination, TooltipClipboard } from '@v2/components';
import { DataTableHeader } from '@v2/components/DataTableHeader';
import { UserAgencyDetailPerformance } from '@v2/types';
import {
  Button,
  Download01,
  Input,
  SearchMD,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@v2/ui';
import { useSession } from 'next-auth/react';
import { AgentColumn } from '../../sales/components/AgentColumn';

const multiColumnFilterFn: FilterFn<UserAgencyDetailPerformance> = (
  row,
  columnId,
  filterValue
) => {
  const searchableRowContent = `${row.original.agentName} ${row.original.affiliationId} `;
  return searchableRowContent.toLowerCase().includes(filterValue.toLowerCase());
};
export const columns: ColumnDef<UserAgencyDetailPerformance>[] = [
  {
    accessorKey: 'affiliationId',
    header: ({ column }) => {
      return (
        <DataTableHeader
          column={column}
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Partner Link
        </DataTableHeader>
      );
    },
    filterFn: multiColumnFilterFn,

    cell: ({ row }) => (
      <>
        <TooltipClipboard
          value={`${process.env.NEXTSITE_URL}/auth/login?af=${row.getValue('affiliationId')}`}
        >
          {row.getValue('affiliationId')
            ? `../auth/login?af=${row.getValue('affiliationId')}`
            : '-'}
        </TooltipClipboard>
      </>
    ),
  },
  {
    enableHiding: true,
    id: 'agentName',
    accessorKey: 'agentName',
    header: ({ column }) => (
      <DataTableHeader
        column={column}
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Agent
      </DataTableHeader>
    ),
    filterFn: multiColumnFilterFn,

    cell: ({ row }) => {
      return <AgentColumn name={row.getValue('agentName')} />;
    },
  },
  {
    accessorKey: 'totalVisits',
    header: ({ column }) => (
      <DataTableHeader
        column={column}
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Total visits
      </DataTableHeader>
    ),
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('totalVisits'));

      return <div>{amount}</div>;
    },
  },
  {
    accessorKey: 'totalUsers',
    header: ({ column }) => (
      <DataTableHeader
        column={column}
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Logged users visits
      </DataTableHeader>
    ),
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('totalUsers'));

      return <div>{amount}</div>;
    },
  },
];

export function PerformancesTable({
  data,
}: {
  data: UserAgencyDetailPerformance[];
}) {
  const { data: sessionData } = useSession();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  // @ts-ignore
  const userRole = sessionData?.user.role;

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  React.useEffect(() => {
    if (userRole) {
      setColumnVisibility((prev) => ({
        ...prev,
        agentName: userRole === USER_ROLE.agency ? true : false,
      }));
    }
  }, [userRole]);

  const isTablet = useBetterMediaQuery('(min-width: 768px)');

  const downloadCSV = () => {
    // Manually construct the CSV content
    const csvRows: string[] = [];
    const headers = [
      'Partner Link',
      'Agent',
      'Total Visits',
      'Logged user visits',
    ];
    csvRows.push(headers.join(','));

    // Iterate over the data and construct each row
    data.forEach((perf) => {
      const row = [
        perf.affiliationId,
        perf.agentName,
        perf.totalVisits,
        perf.totalUsers,
      ];
      csvRows.push(row.join(','));
    });

    // Create a Blob from the CSV content
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'performance.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="flex flex-col items-stretch justify-between gap-y-4 md:flex-row md:items-center">
        <h2 className="text-2xl font-bold text-gray-800 md:text-4xl">
          Performance
        </h2>

        <div className="flex items-center gap-x-3">
          <Input
            extent={isTablet ? 'md' : 'sm'}
            value={
              (table.getColumn('agentName')?.getFilterValue() as string) ?? ''
            }
            onChange={(event) => {
              table.getColumn('agentName')?.setFilterValue(event.target.value);
              table
                .getColumn('affiliationId')
                ?.setFilterValue(event.target.value);
            }}
            placeholder="Search in table"
            startIcon={<SearchMD className="text-gray-500" size="20" />}
          />

          <Button
            variant="secondary"
            className="p-[10px] md:p-[12px]"
            onClick={downloadCSV}
          >
            <Download01 className="text-gray-800" size="20" />
          </Button>
        </div>
      </div>
      <div className="mt-2 rounded-md border md:mt-0">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <DataTablePagination table={table} />
    </>
  );
}
