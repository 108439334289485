import isDevMode from '@utils/dev-mode';

export default function useStorage() {
  if (typeof window !== 'undefined')
    return isDevMode ? localStorage : sessionStorage;
  return {
    setItem: (): string | null => null,
    getItem: (): string | null => null,
    removeItem: (): string | null => null,
    clear: (): void => undefined,
  };
}
