import routes from '@config/routes';
import instance from '@instance';

interface NewsLetterParams {
  email: string;
  tag: string;
}

export function useNewsletterTag() {
  async function addTagToUser(vals: NewsLetterParams) {
    return await instance.server(routes.addTagToUser, {
      body: JSON.stringify({
        ...vals,
      }),
      method: 'POST',
    });
  }

  async function removeTagFromuser(vals: NewsLetterParams) {
    return await instance.server(routes.removeTagFromUser, {
      body: JSON.stringify({
        ...vals,
      }),
      method: 'POST',
    });
  }

  return { addTagToUser, removeTagFromuser };
}
