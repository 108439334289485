'use client';
import { USER_ROLE } from '@utils/auth/roles';
import { v2Links } from '@utils/navigation/links';
import { UserAgencyDetails } from '@v2/types';
import { Typography } from '@v2/ui';
import { genderMap } from '@v2/views/dashboard/user/personalInfo/components';
import { DEFAULT_PLACEHOLDER } from '@v2/views/dashboard/user/personalInfo/util';
import { format } from 'date-fns';
import { useSession } from 'next-auth/react';
import Link from 'next/link';

export function AgencyOwnerDetails({
  agencyOwnerName,
  dateOfBirth,
  email,
  gender,
  phoneNumber,
  agencyId,
}: UserAgencyDetails['agencyOwner'] & { agencyId: number }) {
  const { data } = useSession();

  // @ts-ignore
  const isSuperAdmin = data?.user.role === USER_ROLE.superAdmin;

  if (!isSuperAdmin) return null;

  return (
    <div>
      <div className="mb-5 flex items-center justify-between">
        <h4 className="text-xl font-bold text-gray-800 md:text-2xl">Details</h4>

        <Link
          className="text-base font-medium text-primary-500"
          href={`${v2Links.partnerTeams}?agencyId=${agencyId}`}
        >
          View all members
        </Link>
      </div>

      <div className="grid grid-cols-2 gap-x-6 gap-y-5 md:grid-cols-3">
        <div className="w-full">
          <Typography size="tsm" className="font-medium text-gray-500">
            Name
          </Typography>
          <Typography size="tmd" className="font-semibold text-gray-800">
            {agencyOwnerName}
          </Typography>
        </div>

        <div className="w-full">
          <Typography size="tsm" className="font-medium text-gray-500">
            Date of birth
          </Typography>
          <Typography size="tmd" className="font-semibold text-gray-800">
            {dateOfBirth
              ? format(new Date(dateOfBirth), 'd MMM, yyyy')
              : DEFAULT_PLACEHOLDER}
          </Typography>
        </div>

        <div className="w-full">
          <Typography size="tsm" className="font-medium text-gray-500">
            Gender
          </Typography>
          <Typography size="tmd" className="font-semibold text-gray-800">
            {gender ? genderMap[gender] : DEFAULT_PLACEHOLDER}
          </Typography>
        </div>

        <div className="w-full">
          <Typography size="tsm" className="font-medium text-gray-500">
            Email
          </Typography>
          <Typography
            size="tmd"
            className="max-w-[15ch] truncate font-semibold text-gray-800 md:max-w-[20ch]"
          >
            {email ?? DEFAULT_PLACEHOLDER}
          </Typography>
        </div>
        <div className="w-full">
          <Typography size="tsm" className="font-medium text-gray-500">
            Phone number
          </Typography>
          <Typography size="tmd" className="font-semibold text-gray-800">
            {phoneNumber ?? DEFAULT_PLACEHOLDER}
          </Typography>
        </div>
      </div>
    </div>
  );
}
