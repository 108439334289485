import { Star01 } from '@v2/ui';
import clsx from 'clsx';
import Link from 'next/link';

export type Team = {
  id: number;
  name: string;
  numberOfEvents: number;
  league: string;
  url: string;
};
interface Props {
  team: Team;
  isFavorite?: boolean;
  onFavoriteClick?: (team: Team) => void;
}

export const TeamCard = ({ team, isFavorite, onFavoriteClick }: Props) => {
  const starButtonClasses = clsx('cursor-pointer group-hover:visible', {
    'visible md:invisible': !isFavorite,
    visible: isFavorite,
  });

  return (
    <div className="group flex min-h-[76px] w-full min-w-72 flex-row items-start justify-between rounded-lg border border-gray-200 px-4 pt-4 md:min-h-[90px] md:px-5 md:pt-5">
      <Link
        prefetch={false}
        href={team.url}
        className=""
        suppressHydrationWarning
      >
        <h4
          className="mb-1 text-sm font-semibold md:text-base"
          suppressHydrationWarning
        >
          {team.name}, {team.league}
        </h4>
        <p
          className="text-xs text-gray-500 md:text-sm"
          suppressHydrationWarning
        >{`${team.numberOfEvents} events`}</p>
      </Link>

      <button
        onClick={() => {
          onFavoriteClick?.(team);
        }}
        className={starButtonClasses}
      >
        <Star01
          fill={isFavorite ? '#667085' : ''}
          pathProps={{ stroke: '#667085' }}
        />
      </button>
    </div>
  );
};
