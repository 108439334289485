'use client';

import { LoggedInUserProps } from '@interfaces/auth';
import { Typography } from '@v2/ui';
import { ContentContainer } from '../../components/ContentWrapper';
import {
  Contact,
  Location,
  Manage,
  PersonalContent,
  Security,
} from './components';

export function PersonalInfo({ user }: { user: LoggedInUserProps }) {
  return (
    <ContentContainer>
      <div>
        <Typography size="dmd" className="mb-3 font-bold">
          Personal info
        </Typography>
        <Typography size="tmd" className="text-gray-500">
          Make sure your profile data matches your travel ID, like your passport
          or license.
        </Typography>
      </div>

      <PersonalContent user={user} />
      <Location user={user} />
      <Contact user={user} />
      <Security user={user} />
      <Manage user={user} />
    </ContentContainer>
  );
}
