import {
  getAgencyAffiliateCodes,
  getAgencyMe,
} from '@requests/get-pages-content/agency';
import getUserSession from '@utils/auth/get-user-session';
import { USER_ROLE } from '@utils/auth/roles';
import { v2Links } from '@utils/navigation/links';
import { redirect } from 'next/navigation';
import { ContentContainer } from '../../components/ContentWrapper';
import { AffiliateRewardsTable } from './components';

export async function AFfiliatePartnerRewardsView() {
  const user = await getUserSession();
  const accessToken = user?.token.accessToken;

  const isAMember = user?.role === USER_ROLE.agencyMember;

  if (isAMember) return redirect(v2Links.partnersOverview);

  if (!accessToken) return redirect(`${v2Links.login}?userType=agency`);

  const agencyDetails = await getAgencyMe(accessToken);

  const data = await getAgencyAffiliateCodes({
    agencyId: agencyDetails.id,
    token: accessToken,
  });

  return (
    <ContentContainer className="gap-y-4 md:gap-y-8 lg:max-w-[700px] xl:max-w-[960px]">
      <AffiliateRewardsTable data={data} agencyId={agencyDetails.id} />
    </ContentContainer>
  );
}
