'use client';

import { useAuthUserType } from '@hooks/use-auth-user-type';
import { Button, Logo } from '@v2/ui';
import { ArrowNarrowLeft } from '@v2/ui/Icon/Arrow';
import { useRouter } from 'next/navigation';

type Props = {
  onNavigateBack?: () => void;
  leftSideContent?: React.ReactNode;
  rightSideContent?: React.ReactNode;
};

export const TopHeader = ({
  onNavigateBack,
  rightSideContent,
  leftSideContent,
}: Props) => {
  const router = useRouter();

  const userType = useAuthUserType();
  return (
    <div className="flex h-20 items-center justify-between px-4 md:px-6">
      {leftSideContent ?? (
        <Button
          variant="ghost"
          onClick={() => {
            if (onNavigateBack) return onNavigateBack();

            router.back();
          }}
          className="p-0"
        >
          <ArrowNarrowLeft
            pathProps={{
              stroke: userType === 'agency' ? '#98A2B3' : '#667085',
            }}
          />
        </Button>
      )}
      <Logo
        isClickable
        variant={userType === 'agency' ? 'white' : 'black'}
        size={51}
      />

      <div className="min-w-6">{rightSideContent}</div>
    </div>
  );
};
