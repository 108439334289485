import { Coffee, Gym, Pool, Wifi } from '@v2/ui';
import { Car02 } from '@v2/ui/Icon/MapsAndTravel';

export const STAR_RATINGS_LIST = [1, 2, 3, 4, 5];
export const HOTEL_FEATURES_LIST = [
  {
    label: 'WiFi',
    value: 'WiFi',
    icon: <Wifi size="16" />,
  },
  {
    label: 'Parking',
    value: 'Parking',
    icon: <Car02 size="16" />,
  },
  {
    label: 'Breakfast',
    value: 'Breakfast',
    icon: <Coffee viewBox="0 0 16 16" size="16" />,
  },
  {
    label: 'Pool',
    value: 'Pool',
    icon: <Pool viewBox="0 0 16 16" size="16" />,
  },
  {
    label: 'Fitness',
    value: 'Fitness',
    icon: <Gym viewBox="0 0 16 16" size="16" />,
  },
];

export const HOTEL_AMENETIES = [
  {
    label: 'WiFi',
    value: 'WiFi',
    icon: <Wifi size="16" className="text-gray-500" />,
  },
  {
    label: 'Parking',
    value: 'Parking',
    icon: <Car02 size="16" className="text-gray-500" />,
  },
  {
    label: 'Breakfast',
    value: 'Breakfast',
    icon: <Coffee viewBox="0 0 16 16" size="16" className="text-gray-500" />,
  },
  {
    label: 'Pool',
    value: 'Pool',
    icon: <Pool viewBox="0 0 16 16" size="16" className="text-gray-500" />,
  },
  {
    label: 'Fitness',
    value: 'Fitness',
    icon: <Gym viewBox="0 0 16 16" size="16" className="text-gray-500" />,
  },
];
