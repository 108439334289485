import { useAuthUserType } from '@hooks/use-auth-user-type';
import { v2Links } from '@utils/navigation/links';
import { Button, SVG, Typography } from '@v2/ui';
import { cn } from '@v2/utils';
import Link from 'next/link';

type Props = {
  redirectUrl?: string;
};

export default function CheckYourEmail(props: Props) {
  const userType = useAuthUserType();

  return (
    <div>
      <EmailIcon />

      <Typography
        size="dsm"
        className={cn(
          'mb-[12px] mt-4 text-center',
          userType === 'agency' && 'text-white'
        )}
      >
        Check your email
      </Typography>
      <Typography
        size="tmd"
        className={cn(
          'mb-12 w-full max-w-prose text-center',
          userType === 'agency' ? 'text-gray-400' : 'text-gray-500'
        )}
      >
        We’ve sent you an activation link. Please check your email and click on
        the link to activate your account.
      </Typography>

      <Link href={props.redirectUrl || v2Links.home} className="w-full">
        <Button
          variant="secondary"
          className={cn(
            'w-full',
            userType === 'agency' && 'bg-white text-gray-800'
          )}
        >
          Return to home
        </Button>
      </Link>
    </div>
  );
}

function EmailIcon() {
  const userType = useAuthUserType();

  return (
    <SVG size={'96'} viewBox="0 0 96 96" fill="none" className="mx-auto">
      <g clip-path="url(#clip0_2613_14405)">
        <path
          d="M58.9277 80.8328H2.0625V32.8367L16.2954 22.189"
          stroke={userType === 'agency' ? 'white' : '#1D2939'}
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M83.3228 59.814V32.8367L69.0898 22.189"
          stroke={userType === 'agency' ? 'white' : '#1D2939'}
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M16.2969 44.4611V2.0625H69.0937V44.4611"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.7344 13.4995H57.6579"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M57.6569 24.936H48.4141"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.9772 24.936H27.7344"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.7344 36.373H57.6579"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.6496 64.4424L53.5039 57.1914"
          stroke={userType === 'agency' ? 'white' : '#1D2939'}
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M31.8817 57.1919L2.0625 80.8338"
          stroke={userType === 'agency' ? 'white' : '#1D2939'}
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.0625 32.8369L31.8817 57.192L42.6937 66.0229L53.5056 57.192L83.3248 32.8369"
          stroke={userType === 'agency' ? 'white' : '#1D2939'}
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M88.7184 88.7177C95.678 81.7581 95.678 70.4744 88.7184 63.5148C81.7588 56.5552 70.4751 56.5552 63.5155 63.5148C56.5559 70.4743 56.5559 81.7581 63.5155 88.7177C70.4751 95.6772 81.7588 95.6772 88.7184 88.7177Z"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M76.1164 82.5006V69.7314L71.2422 73.1226"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </SVG>
  );
}
