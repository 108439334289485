import { TextLink, Typography } from '@v2/ui';
import React, { PropsWithChildren } from 'react';
import { v2Links } from '@utils/navigation/links';

import {
  Paragraph,
  SmallHeading,
  Heading,
  SubHeading,
  ListItem,
} from './components';
type Props = {};

function ContentWrapper({ children }: PropsWithChildren) {
  return <div className="flex flex-col gap-y-2.5">{children}</div>;
}
/* eslint-disable */
const data = [
  {
    title: 'Introduction and Definitions',
    content: (
      <>
        <Paragraph>
          Elitesportstours.ca & elitesportstours.com is the online service
          operated by Elite Sports Tours Inc. on the World Wide Web of the
          Internet, consisting of ticketing services, ticketing resources, and
          related content provided by the Company, and by third parties.
          "Subscriber" means every person who establishes a connection for
          access to and use of elitesportstours.ca.{' '}
        </Paragraph>
        <Paragraph>
          Elitesportstours.ca & elitesportstours.com is a private, independently
          owned company, and is not associated with Ticketron, Ticketmaster,
          Tickets.com or any other ticketing company, web-site or agency, box
          office, theatre, stadium or arena. elitesportstours.ca &
          elitesportstours.com is also not associated with and is not an agent
          or authorized representative of any professional or amateur sporting
          or racing league or association, such as MLB, NCAA, NFL, NBA,NHL,
          USTA, PGA, LPGA, USGA, NASCAR, WBA or any association, educational
          institution, club, corporation, partnership, player, team or other
          individual or entity participating in or sponsoring any sporting
          event, or of any other business or charitable artist, association,
          company, corporation, individual, organization, partnership, performer
          or other entity or person conducting, participating in, producing or
          sponsoring any other entertainment event, and all logos and names of
          any of them are their property and used for purposes of factual
          description only.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Agreement with Terms and Conditions',
    content: (
      <>
        <Paragraph>
          This Agreement sets forth the terms and conditions that apply to use
          of elitesportstours.ca & elitesportstours.com by Subscriber. By using
          elitesportstours.ca and elitesportstours.com, Subscriber agrees to
          comply with all of the terms and conditions hereof. The Policies and
          Privacy Statement is incorporated by reference, as if fully set forth
          herein.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Changes in Terms and Conditions',
    content: (
      <>
        <Paragraph>
          The Company has the right to change or discontinue any aspect or
          feature of elitesportstours.ca and elitesportstours.com, including,
          but not limited to, content, hours of availability, and equipment
          needed for access or use, at any time the Company has the right to
          change or modify the terms and conditions applicable to Subscriber's
          use of elitesportstours.ca and elitesportstours.com, or any part
          thereof, or to impose new conditions, including, but not limited to,
          adding fees and charges for use at any time.
        </Paragraph>
        <Paragraph>
          Such changes, modifications, additions or deletions shall be effective
          immediately upon notice thereof, which may be given by means
          including, but not limited to, posting on elitesportstours.ca and
          elitesportstours.com, or by electronic or conventional mail, or by any
          other means by which Subscriber obtains notice thereof. Any use of
          elitesportstours.ca & elitesportstours.com by Subscriber subsequent to
          such notice shall be deemed to constitute acceptance by Subscriber of
          such changes, modifications or additions.
        </Paragraph>
      </>
    ),
  },
  {
    title: ' Conduct of Subscriber',
    content: (
      <>
        <SubHeading>4.1 Lawful Use</SubHeading>
        <Paragraph>
          Subscriber shall use elitesportstours.ca & elitesportstours.com for
          lawful purposes only. Subscriber shall not post or transmit through
          elitesportstours.ca & elitesportstours.com any material which:
        </Paragraph>

        <ol className="flex list-decimal flex-col gap-y-0.5 pl-6">
          <ListItem>
            Violates or infringes in any way upon the rights of others;
          </ListItem>
          <ListItem>
            Is unlawful, threatening, abusive, defamatory, invasive of privacy
            or publicity rights, vulgar, obscene, profane or otherwise
            objectionable;
          </ListItem>
          <ListItem>
            Encourages conduct that would constitute a criminal offense, give
            rise to civil liability or otherwise violate any law; or
          </ListItem>
          <ListItem>
            Contains advertising or any solicitation with respect to products or
            services, unless the Company shall have expressly approved such
            material in advance of its transmission.
          </ListItem>
        </ol>

        <Paragraph>
          Any conduct by a Subscriber that in the Company's discretion restricts
          or inhibits any other Subscriber from using or enjoying
          elitesportstours.ca & elitesportstours.com is expressly prohibited. 
        </Paragraph>

        <SubHeading>4.2 Downloading of Intellectual Property</SubHeading>
        <Paragraph>
          Elitesportstours.ca & elitesportstours.com contains copyrighted
          material, trademarks and other proprietary information, including, but
          not limited to, text, software, photos, video, graphics, music, and
          sound, some of which is owned by the Company and some of which is
          owned by third parties. Furthermore, the entire contents of
          elitesportstours.ca & elitesportstours.com are copyrighted as a
          collective work/compilation. The Company owns copyright in the
          selection, coordination, arrangement, and enhancement of such content,
          as well as in the content original to it. Subscriber may not modify,
          publish, transmit, participate in the transfer or sale, create
          derivative works, or in any way exploit, any of the content, in whole
          or in part.
        </Paragraph>
        <Paragraph>
          Subscriber may download copyrighted material for Subscriber's personal
          use only. Except as otherwise expressly permitted under copyright law,
          no copying, redistribution, retransmission, publication or commercial
          exploitation of downloaded material will be permitted without the
          express written permission of the Company (and the copyright owner if
          other than the Company). In the event of any permitted copying,
          redistribution or publication of copyrighted material, no changes in
          or deletion of author attribution, trademark legend or copyright
          notice shall be made. Subscriber acknowledges that it does not acquire
          any ownership rights by downloading copyrighted material. 
        </Paragraph>

        <SubHeading>4.3 Uploading of Intellectual Property</SubHeading>
        <Paragraph>
          Subscriber shall not upload, post or otherwise make available on
          elitesportstours.ca & elitesportstours.com any material protected by
          copyright, trademark, or other proprietary right, without the express
          written permission of the owner of the copyright, trademark, or other
          proprietary right, and the burden of determining that any material is
          not protected by copyright rests with Subscriber. Subscriber shall be
          solely liable for any damage resulting from any infringement of
          copyrights, proprietary rights, or any other harm resulting from such
          a submission.
        </Paragraph>
        <Paragraph>
          By submitting material to any public area of elitesportstours.ca and
          elitesportstours.com, Subscriber automatically grants, or warrants
          that the owner of such material has expressly granted the Company the
          royalty-free, perpetual, irrevocable, non-exclusive right and license
          to use, reproduce, modify, adapt, publish, translate and distribute
          such material (in whole or in part) worldwide and/or to incorporate it
          in other works in any form, media or technology now known or hereafter
          developed for the full term of any copyright that may exist in such
          material. Subscriber also permits any other Subscriber to access,
          view, store or reproduce the material for that Subscriber's personal
          use. Subscriber hereby grants the Company the rights to edit, copy,
          publish, and distribute any material made available on
          elitesportstours.ca & elitesportstours.com by Subscriber.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Disclaimers and Limitation of Liability',
    content: (
      <div className="space-y-10">
        <div className="space-y-3">
          <SubHeading>5.1 Use Is At Subscriber's Risk</SubHeading>
          <Paragraph>
            Subscriber expressly agrees that use of elitesportstours.ca &
            elitesportstours.com is at subscriber's own risk. Subscriber shall
            be responsible for protecting the confidentiality of subscriber's
            password(s), if any. Neither the company, its affiliates, nor any of
            their respective employees, shareholders, agents, third party
            content providers or licensors, warrant that elitesportstours.ca &
            elitesportstours.com service will be uninterrupted or error free;
            nor do they make any warranty as to the results that may be obtained
            from use of elitesportstours.ca and elitesportstours.com, or as to
            the accuracy, reliability or content of any information, service, or
            merchandise provided through elitesportstours.ca.
          </Paragraph>
        </div>

        <div className="space-y-3">
          <SubHeading>
            5.2 No Warranties Of Elite Sports Tours Inc. Service
          </SubHeading>
          <Paragraph>
            Elitesportstours.ca & elitesportstours.com is provided on an "as is"
            basis without warranties of any kind, either express or implied,
            including, but not limited to, warranties of title or implied
            warranties of merchantability or fitness for a particular purpose,
            other than those warranties which are implied by and incapable of
            exclusion, restriction or modification under the laws applicable to
            this agreement.
          </Paragraph>
        </div>

        <div className="space-y-3">
          <SubHeading>5.3 Disclaimer Of Liability</SubHeading>
          <Paragraph>
            This disclaimer of liability applies to any damages or injury caused
            by any failure of performance, error, omission, interruption,
            deletion, defect, delay in operation or transmission, computer
            virus, communication line failure, theft or destruction or
            unauthorized access to, alteration of, or use of record, whether for
            breach of contract, tortuous behaviour, negligence, or under any
            other cause of action.
          </Paragraph>
          <Paragraph>
            Subscriber specifically acknowledges that the company is not liable
            for the defamatory, offensive or illegal conduct of other
            subscribers or third-parties and that the risk of injury from the
            foregoing rests entirely with subscriber. You are buying tickets
            from a third party; Elite Sports Tours is NOT the ticket seller.
            Ticket prices are set by the seller and may differ from face value.
            ALL SALES ARE FINAL. No refunds, transaction cancellations or
            exchanges will be issued for date/time changes or partial
            performances. Cancelled events will be handled on a case by case
            basis. 
          </Paragraph>
        </div>

        <div className="space-y-3">
          <SubHeading>5.4 Subscriber's Waiver of Damages</SubHeading>
          <div className="mt-4 space-y-4">
            <div>
              <SmallHeading>5.4.1</SmallHeading>
              <Paragraph>
                In no event will the company, or any person or entity involved
                in creating, producing or distributing elitesportstours.ca &
                elitesportstours.com be liable for any damages, including,
                without limitation, direct, indirect, incidental, special,
                consequential or punitive damages arising out of the use of or
                inability to use elitesportstours.ca. Subscriber hereby
                acknowledges that the provisions of this section shall apply to
                all content on elitesportstours.ca.
              </Paragraph>
            </div>
            <div>
              <SmallHeading>5.4.2</SmallHeading>
              <Paragraph>
                Subscriber specifically acknowledges that the company is not
                liable for the defamatory, offensive or illegal conduct of other
                subscribers or third-parties and that the risk of injury from
                the foregoing rests entirely with subscriber. You are buying
                tickets from a third party; Elite Sports Tours is NOT the ticket
                seller. Ticket prices are set by the seller and may differ from
                face value. ALL SALES ARE FINAL. No refunds, transaction
                cancellations or exchanges will be issued for date/time changes
                or partial performances. Cancelled events will be handled on a
                case by case basis.
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    ),
  },

  {
    title: 'Indemnification',
    content: (
      <>
        <Paragraph>
          Subscriber agrees to defend, indemnify and hold harmless the Company,
          its affiliates and their respective directors, officers, shareholders,
          employees, agents, and assigns from and against all claims and
          expenses, including attorneys' fees, arising out of Subscriber's use
          of elitesportstours.ca.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Equipment for Subscriber's Use",
    content: (
      <>
        <Paragraph>
          Subscriber shall be responsible for obtaining and maintaining all
          telephones, computer hardware, and other equipment needed for access
          to and use of elitesportstours.ca & elitesportstours.com and for
          any/all charges related thereto.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Copyrights and Trademarks',
    content: (
      <>
        <Paragraph>
          In addition to the copyright restrictions set forth in Paragraph 4.2
          above, elitesportstours.ca and elitesportstours.com, and its stylized
          version, the stylized version of elitesportstours.ca and
          elitesportstours.com, the daily are trademarks of the Company. All
          rights reserved. All other trademarks appearing on elitesportstours.ca
          & elitesportstours.com are the property of their respective owners.
        </Paragraph>
      </>
    ),
  },
  {
    title: ' Elite Sports Tours Inc. Content',
    content: (
      <>
        <Paragraph>
          A portion of elitesportstours.ca & elitesportstours.com content is
          supplied by third parties. Any opinions, advice, statements, services,
          offers, or other information or content expressed or made available by
          third parties, including information providers, are those of the
          respective author(s) or distributor(s) and not of the Company.
        </Paragraph>
        <Paragraph>
          Neither the Company nor any third-party provider of information
          guarantees the accuracy, completeness, or usefulness of any content,
          nor its merchantability or fitness for any particular purpose. The
          Company neither endorses nor is responsible for the accuracy or
          reliability of any opinion, advice or statement made on
          elitesportstours.ca & elitesportstours.com by anyone other than
          employee spokespersons of the Company while acting in their official
          capacities.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Entire Agreement',
    content: (
      <>
        <Paragraph>
          This Agreement, and any operating rules for elitesportstours.ca &
          elitesportstours.com established by the Company, constitutes the
          entire agreement of the parties with respect to the subject matter
          hereof, and supersedes all previous written or oral agreements between
          the parties with respect to such subject matter.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Controlling Law',
    content: (
      <>
        <Paragraph>
          This Agreement shall be constructed in accordance with the laws of the
          Province of Ontario, without regard to its conflict of laws rules.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Headings',
    content: (
      <>
        <Paragraph>
          The section headings used herein are for convenience only and shall
          not be given any legal import whatsoever.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Partners T&C',
    content: (
      <>
        <TextLink
          legacyBehavior
          // @ts-ignore
          href="https://developer.expediapartnersolutions.com/terms/en"
          // @ts-ignore
          target="_blank"
        >
          Supplier terms and conditions
        </TextLink>
      </>
    ),
  },
];

export function TermsAndCondition({}: Props) {
  return (
    <div className="flex flex-col gap-y-10">
      <div>
        <Typography variant="h1" size="dlg">
          Terms & Conditions
        </Typography>
        <Typography size="tmd" className="mt-5 font-normal text-gray-800">
          Elite Sports Tours Inc. created the following agreement in order to
          clarify the terms and conditions upon which its web site is used.
        </Typography>
      </div>

      <ol className="flex flex-col gap-y-10">
        {data.map(({ content, title }, index) => (
          <li key={title} className="flex flex-col gap-y-4">
            <Heading>{`${index + 1}. ${title}`} </Heading>
            <ContentWrapper>{content}</ContentWrapper>
          </li>
        ))}
      </ol>
    </div>
  );
}
