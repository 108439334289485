import { useEffect, useState } from 'react';
import { MIN_PRICES } from '@config/storageKeys';
import useStorage from '@hooks/useStorage';

const useMinimumPrice = (hotels, tickets, ticketsCount = 1) => {
  const storage = useStorage();
  const initialMinPrices = JSON.parse(storage.getItem(MIN_PRICES) ?? '{}');
  const [ticketPrice, setTicketPrice] = useState(initialMinPrices?.ticket ?? 0);
  const [hotelPrice, setHotelPrice] = useState(initialMinPrices?.hotel ?? 0);
  const [priceLoading, setPriceLoading] = useState(false);

  useEffect(() => {
    if (hotels?.length) {
      let mhp =
        hotels?.reduce((min, hot) => {
          if (hot.price < min) min = hot.price;
          return min;
        }, Number.MAX_SAFE_INTEGER) ?? 0;

      if (mhp === Number.MAX_SAFE_INTEGER) mhp = 0;

      setHotelPrice(mhp);
      const minPrices = JSON.parse(storage.getItem(MIN_PRICES) ?? '{}');
      minPrices.hotel = mhp;
      storage.setItem(MIN_PRICES, JSON.stringify(minPrices));
    }
  }, [hotelPrice, hotels, storage]);

  useEffect(() => {
    const t = tickets?.ticket_groups?.length ? tickets.ticket_groups : tickets;

    if (t?.length) {
      setPriceLoading(true);
      let min = Number.MAX_SAFE_INTEGER;
      t?.forEach((ticket) => {
        if (ticket.retail_price < min && ticket.splits.includes(ticketsCount)) {
          min = ticket.retail_price;
        }
      });

      if (min === Number.MAX_SAFE_INTEGER) min = 0;

      setTicketPrice(min * ticketsCount);
      const minPrices = JSON.parse(storage.getItem(MIN_PRICES) ?? '{}');
      minPrices.ticket = min * ticketsCount;
      storage.setItem(MIN_PRICES, JSON.stringify(minPrices));
      setPriceLoading(false);
    }
  }, [storage, tickets, ticketsCount]);

  return {
    ticketPrice,
    hotelPrice,
    priceLoading,
    setTicketPrice,
    setHotelPrice,
  };
};

export default useMinimumPrice;
