'use client';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import {
  Check,
  Copy05,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@v2/ui';
import { cn } from '@v2/utils';
import { useState } from 'react';

type Props = { value: string; children: React.ReactNode };

export function TooltipClipboard({ value, children }: Props) {
  const [isCopied, setIsCopied] = useState(false);
  const [copiedText, copy] = useCopyToClipboard();

  return (
    <TooltipProvider>
      <Tooltip delayDuration={200}>
        <TooltipTrigger onClick={(event) => event.preventDefault()}>
          {children}
        </TooltipTrigger>

        <TooltipContent
          className={cn(
            'flex items-center gap-x-2',
            isCopied && 'border-gray-800 bg-gray-800'
          )}
          onPointerDownOutside={(event) => event.preventDefault()}
        >
          <button
            className="flex items-center gap-x-2"
            onClick={(e) => {
              e.preventDefault();

              if (isCopied) return;
              copy(value);
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 5000);
            }}
          >
            {isCopied ? (
              <Check size="16" pathProps={{ stroke: 'white' }} />
            ) : (
              <Copy05 size="16" className="text-gray-500" />
            )}

            <span
              className={cn(
                'text-xs font-semibold text-gray-800',
                isCopied && 'text-white'
              )}
            >
              {isCopied ? 'Copied to clipboard!' : 'Copy link'}
            </span>
          </button>

          <TooltipArrow
            className={cn(isCopied && 'fill-gray-800 stroke-gray-800')}
          />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
