import { Typography } from '@v2/ui';
import React, { PropsWithChildren } from 'react';

import { Paragraph, Heading, ListItem } from './components';
type Props = {};

function ContentWrapper({ children }: PropsWithChildren) {
  return <div className="flex flex-col gap-y-2.5">{children}</div>;
}
/* eslint-disable */
const data = [
  {
    title: 'Protecting Your Privacy',
    content: (
      <>
        <Paragraph>
          This Privacy Policy describes Elite Sports Tours Inc collection use
          and/or disclosure of your personal information with respect to your
          use of the services provided by Elite Sports Tours Inc. through its
          websites, including under the domain and subdomains of
          elitesportstours.ca and elitesportstours.com
        </Paragraph>
        <Paragraph>
          By accepting the Privacy Policy and the User Agreement in
          registration, you expressly consent to our collection, storage, use
          and disclosure of your personal information as described in this
          Privacy Policy. This Privacy Policy is effective upon acceptance for
          new users and is otherwise effective on January 1, 2023.
        </Paragraph>
        <Paragraph>
          Your privacy is important to us. Elite Sports Tours Inc.,
          (collectively, "Elite Sports Tours Inc", "us", "we") are committed to
          maintaining the privacy, security and accuracy of the personal
          information they collect, use and disclose in accordance with
          applicable legislation. This privacy policy (the "Privacy Policy") is
          a statement of principles and guidelines concerning the protection of
          personal information of our individual clients, individual service
          providers and other individuals ("you") (with the exception of our
          personnel, whose personal information is collected, used and disclosed
          in accordance with our Personnel Privacy Policy).
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Consent',
    content: (
      <>
        <Paragraph className="font-semibold uppercase">
          BY SUBMITTING PERSONAL INFORMATION TO ELITE SPORTS TOURS INC. OR ITS
          AGENTS, YOU AGREE THAT WE MAY COLLECT, USE AND DISCLOSE SUCH PERSONAL
          INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY, THE PRIVACY
          PREFERENCES YOU HAVE MADE KNOWN TO US AND AS PERMITTED OR REQUIRED BY
          LAW.
        </Paragraph>
        <Paragraph>
          Subject to legal and contractual requirements, you may refuse or
          withdraw your consent to certain of the identified purposes at any
          time by contacting us in writing to, 1469 Gord Vinson Avenue,
          Courtice, ON L1E 3J6 . If you refuse or withdraw your consent, we may
          not be able to provide you or continue to provide you with certain
          services or information pertaining to ELITE SPORTS TOURS INC. that may
          be of value to you.
        </Paragraph>
        <Paragraph>
          Elite Sports Tours Inc. is responsible to protect all personal
          information under its control or transferred to a third party for
          processing. Personal information can include name, home address and
          phone number, age, identification numbers, credit information,
          customer preferences and other items that personally relate to you as
          an individual.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Collection',
    content: (
      <>
        <Paragraph className="">
          Elite Sports Tours Inc. collects certain information through cookies
          and log files. Such information may be used to create a profile of
          users detailing their preferences and to improve the content of the
          site.
        </Paragraph>

        <ol className="flex list-disc flex-col gap-y-0.5 pl-6">
          <ListItem>
            If you choose to register and become a user of the site we may
            request further information, including: user name, password, email
            address, telephone number and other contact information;
          </ListItem>
          <ListItem>
            Your date of birth which, among other things, ensures that all of
            our users are adults and provides us general demographic
            information;
          </ListItem>
          <ListItem>
            Financial information, such as credit card or bank account numbers;
          </ListItem>
          <ListItem>
            Shipping, billing and other information you provide to purchase or
            ship an item;
          </ListItem>
          <ListItem>
            Other information from your interaction with our sites, services,
            content and advertising, including computer and connection
            information, statistics on page views, traffic to and from the
            sites, ad data, internet protocol address and standard web log
            information; and
          </ListItem>
          <ListItem>
            Additional information for identity authentication purposes.
          </ListItem>
        </ol>
      </>
    ),
  },

  {
    title: 'Marketing',
    content: (
      <>
        <Paragraph>
          Elite Sports Tours Inc. does not sell personal information to third
          parties for marketing purposes without explicit consent.
        </Paragraph>
        <Paragraph>
          Elite Sports Tours Inc. may partner with reputable third parties to
          offer users a variety of services. Many of these services also require
          registration to make them function properly. Co-branded websites are
          sites that are labelled with another corporation's logos or
          trademarks.
        </Paragraph>
        <Paragraph>
          Although we only work with reputable third parties, our third party
          partners may have different privacy policies and practices. We
          encourage you to review our business partners' privacy policies
          further to understand their procedures for collecting, using and
          disclosing personal information.
        </Paragraph>
        <Paragraph>
          All contest rules & regulations are posted with every contest
          conducted.
        </Paragraph>
        <Paragraph>
          If you choose not to receive future marketing communications from us:
          you can send an email to info@elitesportstours.ca or click the
          unsubscribe link at the bottom of the email communication. In the
          event that you may win one of our contests, we reserve the right to
          publish your full name and city of origin on our website.
        </Paragraph>
        <Paragraph>
          EliteSportsTours.com does not record or monitor your web browser
          cookies or your IP address during routine visits to our site. Using
          analytic tools we are able to note the internet browser you are using
          to view our site. We use this information in order to optimize the
          functionality of our site for specific web browsers only.
        </Paragraph>
        <Paragraph>
          Parameters involved with external advertising featured on the site
          (outside links, web cookies, etc.) are not under the control of
          EliteSportsTours.com and are covered under separate private policies.
          In order to control / monitor pop-ups, web cookies, etc., please view
          the tool options available to you on your web browser.
        </Paragraph>
        <Paragraph>
          EliteSportsTours.com is not responsible for the privacy practices or
          the content of outside websites. The extended reach of
          EliteSportsTours.com on social platforms such as Facebook, Instagram
          and Twitter, is not governed by the EliteSportsTours.com Privacy
          Policy, and instead follows the rules, regulations and privacy
          policies of those external sites.
        </Paragraph>
      </>
    ),
  },

  {
    title: 'Use',
    content: (
      <>
        <Paragraph>
          Elite Sports Tours collects personal information to provide you with
          safe and efficient services, and to customize our site to your
          preferences. Users agree that Elite Sports Tours Inc. may use personal
          information to:
        </Paragraph>

        <ol className="flex list-disc flex-col gap-y-0.5 pl-6">
          <ListItem>
            Provide the services and customer support as requested;
          </ListItem>
          <ListItem>
            Resolve disputes, collect fees, and troubleshoot problems;
          </ListItem>
          <ListItem>
            Prevent potentially prohibited or illegal activities, and enforce
            our User Agreement;{' '}
          </ListItem>
          <ListItem>
            Customize, measure and improve our services, content and
            advertising;
          </ListItem>
          <ListItem>
            Tell you about our services and those of our corporate family,
            targeted marketing, service updates, and promotional offers based on
            your communication preferences; and
          </ListItem>
          <ListItem>
            Compare information for accuracy, and verify it with third parties.
          </ListItem>
        </ol>

        <Paragraph>
          Travel agents and web reservation assistants at EliteSportsTours.com
          use third party software and databases to process travel and event
          ticket arrangements and also share information with our Partners (e.g.
          Air Canada, American Airlines, Hotels.com, Priceline, etc.), as well
          as payment processors. Networks used to access these Partners are
          highly secure and our partners only use client information such as
          names, dates of birth, secured credit card information, etc. for the
          purposes of administration with travel plans, event ticket purchases,
          processing payments, fraud verification, account collection (when
          applicable) and to enforce security protocols. Procedures when
          accessing external databases and our internal travel and accounting
          database are stringent, with security being the main priority.
        </Paragraph>
        <Paragraph>
          Users purchasing or selling tickets will also receive a number of
          emails throughout the transaction process and may receive phone calls
          from our Customer Service department.
        </Paragraph>
        <Paragraph>
          Elite Sports Tours collects personal information to provide you with
          safe and efficient services, and to customize our site to your
          preferences. Users agree that Elite Sports Tours Inc. may use personal
          information to:
        </Paragraph>
        <Paragraph>
          Users submitting testimonials, photographs, customer satisfaction
          surveys or other writings consent to the Elite Sports Tours Inc.
          providing such submissions together with the user's name and location
          in any and all media.
        </Paragraph>
        <Paragraph>
          When purchasing a flight, we require personal information in order to
          proceed. It is necessary to have the full names of all passengers (as
          they appear on travel documents), the dates of birth for each
          passenger and the address of the person making the booking for travel
          planning and security protocols, and a valid email address of the main
          booking client. Credit card information (full number, expiry date and
          security access code) is gathered for all purchases made online or
          through a telephone booking with one of our Specialists. All this
          information is considered “personal information”
        </Paragraph>
        <Paragraph>
          All personal information collected during the booking process is
          protected by our secure network using SSL technology protocols and
          normal private network security systems. SSL technology also encrypts
          your credit card information before it is sent to the supplier and/or
          tour operator.
        </Paragraph>
        <Paragraph>
          It is necessary to obtain your email address so we can send all
          itinerary information, e-tickets and other documents you may require
          for your purchased products and/or services.
        </Paragraph>
      </>
    ),
  },

  {
    title: 'Who is authorized to make purchases on EliteSportsTours.com',
    content: (
      <>
        <Paragraph>
          Adults over 18 years of age are able to book travel services online.
          If you are under 18 years of age, you may purchase travel services
          over the phone with a legal guardian present, or by coming into our
          offices with a letter of consent from a parent or legal guardian.
        </Paragraph>
        <Paragraph>
          If you are booking travel for someone else, it is up to you to obtain
          consent from that individual to share their personal information (e.g.
          contact details, credit card numbers, etc.). Our verification
          procedures keep any unwanted charges from occurring on yours or a
          third party’s credit card. All fraudulent purchases will be
          investigated and prosecuted.
        </Paragraph>
      </>
    ),
  },

  {
    title: 'Disclosure of Information',
    content: (
      <>
        <Paragraph>
          Elite Sports Tours Inc. may disclose personal information to respond
          to legal requirements, enforce our policies, respond to claims that a
          listing or other content violates the rights of others, or protect
          anyone's rights, property, or safety. Such information will be
          disclosed in accordance with applicable laws and regulations. Elite
          Sports Tours Inc. may also share information with its parent company,
          subsidiaries, joint ventures or other corporations under common
          control.
        </Paragraph>
        <Paragraph>We may also share your personal information with:</Paragraph>
        <ol className="flex list-disc flex-col gap-y-0.5 pl-6">
          <ListItem>
            Service providers under contract who help with our business
            operations (such as fraud investigations, bill collection, affiliate
            and rewards programs and co-branded credit cards).
          </ListItem>
          <ListItem>
            Law enforcement or other governmental officials, in response to a
            verified request relating to a criminal investigation or alleged
            illegal activity.
          </ListItem>
        </ol>

        <Paragraph>
          Elite Sports Tours Inc. may also disclose information about users to
          third parties who provide services to Elite Sports Tours Inc., such
          services may include verifying credit card information, fulfilling
          ticket orders via courier delivery, processing credit card
          transactions, and disbursing payments to sellers.
        </Paragraph>
      </>
    ),
  },

  {
    title: 'Security',
    content: (
      <>
        <Paragraph>
          We take all measures we believe to be reasonably appropriate to
          safeguard your personal information against its unauthorized access,
          disclosure, copying, modification or misuse. Access to personal
          information is restricted to employees who need such information for a
          legitimate business purpose and such employees are instructed to abide
          by this Private Policy and Elite Sports Tours Inc. privacy practices.
          Once your personal information is no longer required to fulfill our
          stated purposes, we take proper measures to destroy or erase it.
        </Paragraph>
        <Paragraph>
          Should you have any questions about this Privacy Policy, or the manner
          in which Elite Sports Tours Inc. handles your personal information,
          you may write to us at:
        </Paragraph>
        <Paragraph>
          Elite Sports Tours
          <br />
          Privacy Officer
          <br />
          1469 Gord Vinson Ave.
          <br />
          Courtice, Ontario L1E 3J6
        </Paragraph>
      </>
    ),
  },
];

export function PrivacyPolicy({}: Props) {
  return (
    <div className="flex flex-col gap-y-10">
      <div>
        <Typography variant="h1" size="dlg" className="mb-5">
          Privacy Policy
        </Typography>
        <ContentWrapper>
          <Typography size="tmd" className="font-normal text-gray-800">
            At Elite Sports Tours Inc., we prioritize the privacy, security, and
            confidentiality of all our customers and web users. Maintaining the
            trust of everyone who interacts with EliteSportsTours.com is crucial
            to us. Simultaneously, we strive to guarantee our website's
            efficiency and user-friendliness.
          </Typography>
          <Typography size="tmd" className="font-normal text-gray-800">
            It is the responsibility of the user to read and accept the Terms
            and Conditions of Elite Sports Tours inc., hereby known in these
            Terms and Conditions as EliteSportsTours.com, before purchasing
            services through the EliteSportsTours.com website and/or its
            affiliate sites. We reserve the right to update and/or modify these
            Terms when needed. It is the responsibility of the user to return to
            this page to review updated versions of our Terms of Use / Terms &
            Conditions.
          </Typography>
          <Typography size="tmd" className="font-normal text-gray-800">
            Elite Sports Tours inc. (EliteSportsTours.com) is compliant with the
            Personal Information Protection and Electronic Document Act of
            Canada.
          </Typography>
        </ContentWrapper>
      </div>

      <ol className="flex flex-col gap-y-10">
        {data.map(({ content, title }, index) => (
          <li key={title} className="flex flex-col gap-y-4">
            <Heading>{title}</Heading>

            <ContentWrapper>{content}</ContentWrapper>
          </li>
        ))}
      </ol>
    </div>
  );
}
