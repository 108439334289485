'use client';
import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { v2Links } from '@utils/navigation/links';
import { Button } from '@v2/ui';
import Image from 'next/image';
import Link from 'next/link';

export function CallToAction() {
  const isTablet = useBetterMediaQuery('(min-width: 768px)');

  return (
    <div className="relative mx-4 rounded-lg bg-slate-950 px-4 py-10 lg:px-20 lg:py-20">
      <Image
        src={'/events-hero-image/affiliate.png'}
        alt="Background Image"
        layout="fill"
        objectFit="cover"
        className="absolute inset-0 z-0"
        loading="lazy"
      />

      <div className="relative flex flex-col items-center">
        <h3 className="mb-4 text-2xl font-bold text-white lg:mb-5 lg:text-5xl lg:tracking-[-0.96px]">
          Book your trip now
        </h3>
        <p className="mb-6 text-sm font-medium text-white lg:mb-10 lg:text-lg">
          {' '}
          Find the best deals on hotel & ticket packages.s
        </p>
        <Link href={v2Links.home} prefetch={false}>
          <Button size={isTablet ? '2xl' : 'md'} className="mx-auto w-max">
            Search now
          </Button>
        </Link>
      </div>
    </div>
  );
}
