import { useAuthUserType } from '@hooks/use-auth-user-type';
import { Typography, typographyVariants } from '@v2/ui/Typography';
import { cn } from '@v2/utils/cn';
import { motion } from 'framer-motion';
import usePasswordStrength from './use-password-strength';

export function PasswordStrengthVisual({ password }) {
  const { scope, strengthCategory } = usePasswordStrength(password);

  const userType = useAuthUserType();
  const hasMinLength = password.length >= 8 && password.length <= 24;
  const hasLettersAndNumbers = /[a-zA-Z]/.test(password) && /\d/.test(password);
  const hasSpecialCharacters = /[~#@$%&!*?^-]/.test(password);
  return (
    <div>
      <Typography
        variant="p"
        size={'tsm'}
        className={cn(
          'mb-1 flex w-full items-center justify-between font-medium text-gray-500',
          userType === 'agency' && 'text-gray-400'
        )}
      >
        Password strength
        <span className="">{strengthCategory}</span>
      </Typography>
      <div className="relative mb-3 h-2 w-full overflow-hidden rounded-full bg-gray-200">
        <motion.div
          ref={scope}
          className="absolute left-0 top-0 h-full rounded-full"
        ></motion.div>
      </div>
      <ul className="list-disc pl-4">
        {!hasMinLength && (
          <li
            className={cn(
              typographyVariants({ size: 'tsm' }),
              userType === 'agency' ? 'text-gray-400' : 'text-gray-500'
            )}
          >
            Include 8-24 characters
          </li>
        )}
        {!hasLettersAndNumbers && (
          <li
            className={cn(
              typographyVariants({ size: 'tsm' }),
              userType === 'agency' ? 'text-gray-400' : 'text-gray-500'
            )}
          >
            Combine letters and numbers
          </li>
        )}
        {!hasSpecialCharacters && (
          <li
            className={cn(
              typographyVariants({ size: 'tsm' }),
              userType === 'agency' ? 'text-gray-400' : 'text-gray-500'
            )}
          >
            Include special characters ~ # @ $ % & ! * _ ? ^ -
          </li>
        )}
      </ul>
    </div>
  );
}
