import { getAgencyDetailsOverview } from '@requests/get-pages-content/agency';
import { USER_ROLE } from '@utils/auth/roles';
import { Suspense } from 'react';
import { ContentContainer } from '../../components/ContentWrapper';
import { TopOverview } from './components';
import { AffiliateLink } from './components/AffiliateLink';
import { AgencyFullDetails } from './components/AgencyFullDetails';
import { AgencyOwnerDetails } from './components/AgencyOwnerDetails';
import {
  OverviewAgencyDetails,
  OverviewDetailsSkeleton,
} from './components/OverviewSkeleton';
interface AffiliateOverviewProps {
  agencyId: number;
  accessToken: string;
  userRole: USER_ROLE;
}

export function Overview(props: AffiliateOverviewProps) {
  const { agencyId, accessToken, userRole } = props;
  return (
    <ContentContainer className="gap-y-8 md:gap-y-10">
      <Suspense fallback={<OverviewDetailsSkeleton />}>
        <TopOverview
          agencyId={agencyId}
          accessToken={accessToken}
          userRole={userRole}
        />{' '}
      </Suspense>
      <Suspense fallback={<OverviewAgencyDetails />}>
        <AgencyDetails agencyId={agencyId} accessToken={accessToken} />
      </Suspense>
    </ContentContainer>
  );
}

export async function AgencyDetails(props: {
  agencyId: number;
  accessToken: string;
}) {
  const { agencyId, accessToken: token } = props;

  const details = await getAgencyDetailsOverview({
    token,
    agencyId: agencyId,
  });

  const affiliateLink = details?.affiliateLink;
  return (
    <>
      {<AgencyOwnerDetails {...details.agencyOwner} agencyId={agencyId} />}
      {affiliateLink && (
        <AffiliateLink label="Partner Link" affiliateLink={affiliateLink} />
      )}
      <AgencyFullDetails {...details} />
    </>
  );
}
