'use client';
import { cn } from '@v2/utils';
type PAGES_ACTIVE = 'Stay' | 'Tickets' | 'Review';

type Props = {
  active: PAGES_ACTIVE;
  className?: string;
};

import {
  PACKAGE_STORAGE,
  SELECTED_HOTEL_STORAGE,
  SELECTED_TICKET_STORAGE,
} from '@config/storageKeys';
import useStorage from '@hooks/useStorage';
import { IFormValues } from '@interfaces/buildPackage';
import { links, v2Links } from '@utils/navigation/links';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@v2/ui';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useState } from 'react';

export function BuildPackageSteps({ active = 'Stay', className }: Props) {
  const storage = useStorage();
  const [currentPackageData, setCurrentPackageData] = useState<IFormValues>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );

  const selectedHotel = JSON.parse(
    storage.getItem(SELECTED_HOTEL_STORAGE) || '{}'
  );
  const selectedTicket = JSON.parse(
    storage.getItem(SELECTED_TICKET_STORAGE) || '{}'
  );

  const searchParams = useSearchParams();

  const eventId = searchParams!.get('eventId')!;

  const router = useRouter();
  const pathName = usePathname();

  if (!currentPackageData) {
    router.replace(`${v2Links.buy}?eventId=${eventId}`);

    return null;
  }
  const { selected } = currentPackageData;

  const hasUserCompleted = selectedTicket && selectedHotel;

  return (
    <Breadcrumb className={cn('mb-0 px-4 md:px-0', className)}>
      <BreadcrumbList>
        {selected?.hotel && (
          <>
            <BreadcrumbItem>
              <BreadcrumbLink
                href={`${v2Links.searchHotel}?eventId=${eventId}`}
                isCurrentPage={active === 'Stay'}
              >
                Stay
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbSeparator />
          </>
        )}

        {selected?.tickets && (
          <>
            <BreadcrumbItem>
              <BreadcrumbLink
                href={`${v2Links.searchTickets}?eventId=${eventId}`}
                isCurrentPage={active === 'Tickets'}
              >
                Tickets
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbSeparator />
          </>
        )}

        <BreadcrumbItem>
          <BreadcrumbLink
            href={hasUserCompleted ? links.checkout : '#'}
            isCurrentPage={active === 'Review'}
          >
            Review
          </BreadcrumbLink>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
}
