import type { RoomSelection } from '@interfaces/expedia/property';
import { create } from 'zustand';

export interface SearchHotelState {
  isMapFullScreen: boolean;
  hasPricePerTravelerChanged: boolean;
  hasDistanceChanged: boolean;
  setHotelFilters: (filters: Partial<SearchHotelState['hotelFilters']>) => void;
  setHotelRange: (
    filters: Partial<
      Pick<SearchHotelState['hotelFilters'], 'pricePerTraveler' | 'distance'>
    >
  ) => void;
  reset: () => void;
  setHotelSelectedInMap: (val: number | null, isOpen: boolean) => void;
  resetHotelFilters: () => void;
  hotelFilters: {
    featureHotel: string[];
    pricePerTraveler: number[];
    rating: string[];
    sortBy: string;
    distance: number[];
    query: string;
  };

  hotelDrawerSelected: {
    isOpen: boolean;
    hotelId: number | null;
  };

  roomSelections: RoomSelection[];
  setHotelRoomSelection: (val: RoomSelection) => void;
  removeHotelRoomSelection: (index: number) => void;
  selectableOccupancies: string[];
}

export const DEFAULT_DISTANCE = [15];
export const DEFAULT_PRICE_PER_TRAVELER = [0, 6500];

const initValues = {
  roomSelections: [],
  hasPricePerTravelerChanged: false,
  hasDistanceChanged: false,
  isMapFullScreen: false,
  hotelFilters: {
    featureHotel: [],
    pricePerTraveler: DEFAULT_PRICE_PER_TRAVELER,
    rating: [],
    sortBy: 'distance-low-high',
    distance: DEFAULT_DISTANCE,
    query: '',
  },
  hotelDrawerSelected: {
    isOpen: false,
    hotelId: null,
  },
  selectableOccupancies: [],
};

export const useSearchHotelStore = create<SearchHotelState>((set, get) => ({
  ...initValues,

  setHotelSelectedInMap: (val, isOpen) =>
    set({
      hotelDrawerSelected: {
        hotelId: val,
        isOpen: isOpen ?? get().hotelDrawerSelected.isOpen,
      },
    }),

  setHotelFilters: (filters) => {
    set((state) => ({
      hotelFilters: {
        ...state.hotelFilters,
        ...filters,
      },
    }));
  },
  setHotelRoomSelection: (room) => {
    set((state) => ({
      roomSelections: [...state.roomSelections, room],
    }));
  },

  removeHotelRoomSelection: (index) => {
    set((state) => {
      const newSelections = [...state.roomSelections];
      newSelections.splice(index, 1);
      return { roomSelections: newSelections };
    });
  },

  setHotelRange: (filters) => {
    set((state) => {
      const newState = {
        ...state,
        hotelFilters: {
          ...state.hotelFilters,
          ...filters,
        },
      };

      if ('distance' in filters) {
        newState.hasDistanceChanged = true;
      }

      if ('pricePerTraveler' in filters) {
        newState.hasPricePerTravelerChanged = true;
      }

      return newState;
    });
  },
  resetHotelFilters: () => {
    set((state) => ({
      hotelFilters: {
        ...initValues.hotelFilters,
      },
    }));
  },
  reset: () => {
    set(initValues);
  },
}));
