export const leagues = [
  'mlb',
  'nba',
  'nfl',
  'nhl',
  'ncaa-mens-football',
  'ncaa-mens-basketball',
  'mls-major-league-soccer',
];

export const eventTypes = [
  'concerts',
  'sports',
  'baseball',
  'basketball',
  'hockey',
  'football',
  'nhl',
  'soccer',
  'pga',
  'formula-1',
  'nascar',
  'ufc',
];

export const sports = ['pga', 'formula-1', 'nascar', 'ufc'];

export const labels = {
  mlb: 'MLB',
  nba: 'NBA',
  nfl: 'NFL',
  nhl: 'NHL',
  mls: 'MLS',
  pga: 'PGA',
  'formula-1': 'Formula 1',
  nascar: 'Nascar',
  ufc: 'UFC',
  'ncaa-mens-football': 'NCAA Football',
  'ncaa-mens-basketball': 'NCAA Basketball',
};

export const popular = ['mlb', 'nba', 'nfl', 'nhl'];

export const tevoSlugs = {
  'ncaa-mens-basketball': 'ncaa-men--2',
  'ncaa-mens-football': 'ncaa--2',
  ufc: 'mixed-martial-arts-mma',
  mlb: 'mlb',
  nba: 'nba',
  nfl: 'nfl',
  nhl: 'nhl',
  mls: 'mls-major-league-soccer',
  pga: 'pga',
  'formula-1': 'formula-1',
  nascar: 'nascar',
};
