'use client';

import { cn } from '@v2/utils';
import React, { useEffect } from 'react';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
  Popover,
  PopoverContent,
  PopoverTrigger,
  typographyVariants,
} from '@v2/ui';
import useSWR from 'swr';
import { apiFetcher } from '@instance';
import routes from '@config/routes';
import { useDebounce } from '@uidotdev/usehooks';

export interface LocationItem {
  description: string;
  Text: string;
  PlaceId?: string;
}

type Props = {
  children: React.ReactNode;
  value: string;
  onChange: (value: LocationItem) => void;
  hasSearch?: boolean;
};

export function LocationDropdown({
  children,
  onChange,
  value,
  hasSearch = true,
}: Props) {
  const [searchValue, setSearchValue] = React.useState(value);
  const [isOpen, setIsOpen] = React.useState(false);
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const { data, isValidating, isLoading, mutate } = useSWR(
    routes.getLatLngAws,
    () =>
      apiFetcher(routes.geocodeAws, {
        body: JSON.stringify({ location: debouncedSearchTerm }),
      })
  );

  useEffect(() => {
    if (debouncedSearchTerm) {
      mutate();
    }
  }, [debouncedSearchTerm]);

  const handleSelect = React.useCallback(
    (country: LocationItem) => {
      onChange(country);
    },
    [onChange]
  );
  return (
    <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent
        className="popover-content-width-same-as-its-trigger min-w-full bg-white p-0"
        sideOffset={1}
        align="start"
      >
        <Command shouldFilter={false}>
          <CommandList>
            {hasSearch && (
              <CommandInput
                onClose={() => {
                  setSearchValue('');
                }}
                value={searchValue}
                placeholder="Search country..."
                onValueChange={(val) => {
                  setSearchValue(val);
                }}
              />
            )}
            {isValidating && <CommandLoading>Loading....</CommandLoading>}

            {!isValidating && !data?.length && (
              <CommandEmpty>No country found.</CommandEmpty>
            )}
            <CommandGroup>
              {!isValidating &&
                data?.length &&
                data
                  .filter((x) => x.Text)
                  .filter((x) => x?.PlaceId)
                  .map((option) => (
                    <CommandItem
                      disabled={false}
                      className="px-4 py-3"
                      key={option.Text}
                      onSelect={(item) => {
                        handleSelect(option);
                        setIsOpen(false);
                      }}
                      aria-selected={value === option.Text}
                    >
                      <span
                        className={cn(
                          typographyVariants({ size: 'tmd' }),
                          'font-semibold text-gray-800'
                        )}
                      >
                        {option.Text}
                        {/* - {option.country} */}
                      </span>
                    </CommandItem>
                  ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
