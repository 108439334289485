/* eslint-disable no-console */
import crypto from 'crypto';
import Qs from 'qs';

const SIGNATURE_ALGORITHM = 'sha256';
const SIGNATURE_ENCODING = 'base64';

const queryStringToObject = (s: string) => {
  return Qs.parse(s);
};

type ObjType = { [key: string]: string | number };
type TevoClientOptions = {
  apiToken: string;
  apiSecretKey: string;
};

type RequestType = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

type PostOptions = {
  body: string;
  method: RequestType;
  href: string;
  Accept: 'application/json';
  'Content-Type': 'application/json';
};

type Options = {
  body?: never;
  method: RequestType;
  href: string;
  secret?: string;
};

type GenerateHeaders = {
  'X-Token': string;
  'X-Signature': string;
  Accept: 'application/vnd.ticketevolution.api+json; version=9';
};

const queryObjectToString = (obj: ObjType): string => {
  const parts: string[] = [];
  let keys = Object.keys(obj);
  keys = keys.sort();
  keys.forEach((key) => {
    const subObj: ObjType = {};
    subObj[key] = obj[key];
    const part = Qs.stringify(subObj, { arrayFormat: 'brackets' });
    parts.push(part);
  });
  return parts.join('&');
};

class TevoClient {
  private readonly apiToken: string;

  private readonly apiSecretKey: string;

  constructor(options: TevoClientOptions) {
    if (!options.apiToken) {
      console.error('You must provide tevoAuth.Client with a valid apiToken.');
    }
    if (!options.apiSecretKey) {
      console.error(
        'You must provide tevoAuth.Client with a valid apiSecretKey.'
      );
    }
    this.apiToken = options.apiToken;
    this.apiSecretKey = options.apiSecretKey;
  }

  static makeSignature(
    method: RequestType,
    href: string,
    secret: string,
    body?: string
  ) {
    // http://stackoverflow.com/questions/736513/how-do-i-parse-a-url-into-hostname-and-path-in-javascript
    const reURLInformation = new RegExp(
      [
        '^(https?:)//', // protocol
        '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
        '(/[^?#]*)', // pathname
        '(\\??)', // question mark
        '([^#]*|)', // search
        '(#.*|)$', // hash
      ].join('')
    );
    const match = href.match(reURLInformation);
    if (!match) {
      console.error('makeSignature()', 'Invalid href.');
      return;
    }
    const hostname = match[3];
    // const isHttps = (match[1] == HTTPS); Ignored when computing signature.
    const path = match[5];
    // const port = match[4]; Ignored when computing signature.
    const querystring = match[7];
    // eslint-disable-next-line consistent-return
    return TevoClient.makeSignatureFromParts({
      hostname,
      method,
      path,
      querystring,
      body,
      secret,
    });
  }

  static makeSignatureFromParts(options: any) {
    const defaultOptions = {
      hostname: 'localhost',
      method: 'GET',
      path: '/',
      body: null,
      querystring: '',
    };
    options = { ...defaultOptions, ...options };

    let { querystring } = options;
    querystring = queryStringToObject(querystring);
    querystring = queryObjectToString(querystring);
    const stringToSign = `${options.method} ${options.hostname}${options.path}?${querystring}${options.body ?? '' /* POST requests */}`;

    return crypto
      .createHmac(SIGNATURE_ALGORITHM, options.secret)
      .update(stringToSign)
      .digest(SIGNATURE_ENCODING);
  }

  private generateHeaders(options: Options | PostOptions): GenerateHeaders {
    const sig = TevoClient.makeSignature(
      options.method,
      options.href,
      this.apiSecretKey,
      options.body
    );
    return {
      'X-Token': this.apiToken,
      'X-Signature': sig as string,
      Accept: 'application/vnd.ticketevolution.api+json; version=9',
    };
  }

  private async getJson(href: string) {
    const result = await fetch(href, {
      method: 'GET',
      headers: this.generateHeaders({ method: 'GET', href }),
    });
    return result.json();
  }

  async postJSON<T = any>(href: string, body: object): Promise<T> {
    if (!body) body = {};
    const bodyJSON = JSON.stringify(body);
    const headers = this.generateHeaders({
      href,
      method: 'POST',
      body: bodyJSON,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return fetch(href, {
      headers,
      body: bodyJSON,
      method: 'POST',
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch(console.error);
  }

  async getResponse(data: string): Promise<any> {
    //console.log('-> TevoRequest', JSON.stringify(data));
    return this.getJson(data);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const testSign = new TevoClient({
  apiToken: process.env.TICKET_EVO_API_TOKEN as string,
  apiSecretKey: process.env.TICKET_EVO_API_SECRET as string,
});
