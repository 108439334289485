import create from 'zustand';

type MobileContentType = 'Auth' | 'Preview' | 'Checkout';

interface MobileStore {
  mobileContentType: MobileContentType;
  setMobileContentType: (type: MobileContentType) => void;
}

export const useMobileCheckoutStore = create<MobileStore>((set) => ({
  mobileContentType: 'Preview', // initial state
  setMobileContentType: (type: MobileContentType) =>
    set({ mobileContentType: type }),
}));
