'use client';

import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { cn } from '@v2/utils';

const SliderTrack = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Track>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Track>
>(({ children, ...props }, ref) => (
  <SliderPrimitive.Track
    ref={ref}
    className="relative h-2 w-full grow overflow-hidden rounded-full bg-gray-300"
    {...props}
  >
    {children}
  </SliderPrimitive.Track>
));

const SliderThumb = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Thumb>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Thumb>
>(({ ...props }, ref) => (
  <SliderPrimitive.Thumb
    ref={ref}
    {...props}
    className="ring-offset-background focus-visible:ring-ring block h-5 w-5 rounded-full border-2 border-gray-800 bg-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
  />
));

SliderThumb.displayName = SliderPrimitive.Thumb.displayName;

const SliderRange = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Range>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Range>
>(({ ...props }) => (
  <SliderPrimitive.Range {...props} className="absolute h-full bg-gray-800" />
));

SliderRange.displayName = SliderPrimitive.Range.displayName;

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className
    )}
    {...props}
  >
    {children}
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider, SliderRange, SliderThumb, SliderTrack };
