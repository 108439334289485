import { FC } from 'react';
import classes from './styles.module.scss';

interface ProgressProps {
  loading?: boolean;
}

const Progress: FC<ProgressProps> = ({ loading }) =>
  loading && (
    <div className={classes.root}>
      <span className={classes.loader} />
    </div>
  );

export default Progress;
