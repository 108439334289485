const TopCard = () => (
  <div className="h-60 w-full rounded-lg border border-gray-200 p-6 md:w-72">
    <div className="mb-2 h-7 w-2/3 rounded-lg bg-gray-100"></div>
    <div className="mb-6 h-11 w-1/3 rounded-lg bg-gray-100"></div>
    <div className="mb-2 h-6 w-full rounded-lg bg-gray-100"></div>
    <div className="mb-2 h-6 w-full rounded-lg bg-gray-100"></div>
    <div className="mb-2 h-6 w-full rounded-lg bg-gray-100"></div>
  </div>
);

export const OverviewDetailsSkeleton = () => {
  return (
    <>
      <div className="mb-10 h-11 w-40 rounded-lg bg-gray-100"></div>
      <div className="mb-10 flex w-full flex-wrap gap-4">
        <TopCard />
        <TopCard />
        <TopCard />
      </div>
    </>
  );
};

export const OverviewAgencyDetails = () => {
  return (
    <>
      <div className="mb-5 h-8 w-32 rounded-lg bg-gray-100"></div>
      <div className="mb-10 h-16 w-full rounded-lg bg-gray-100"></div>
      <div className="mb-5 h-8 w-32 rounded-lg bg-gray-100"></div>
      <div>
        <div className="mb-5 h-6 w-full rounded-lg bg-gray-100"></div>
        <div className="mb-5 h-6 w-full rounded-lg bg-gray-100"></div>
        <div className="mb-5 h-6 w-full rounded-lg bg-gray-100"></div>
        <div className="mb-5 h-6 w-full rounded-lg bg-gray-100"></div>
      </div>
    </>
  );
};

export const OverviewSkeleton = () => {
  return (
    <div className="w-full animate-pulse">
      <OverviewDetailsSkeleton />
      <OverviewAgencyDetails />
    </div>
  );
};
