export const AgentColumn = ({ name }) => {
  const [firstName, lastName] = name.split(' ');

  return (
    <div className="flex w-max flex-row items-center gap-x-2">
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 text-sm font-medium text-gray-500">
        {firstName[0]}
        {lastName[0]}
      </div>
      {name}
    </div>
  );
};
