'use client';

import { useAuthUserType } from '@hooks/use-auth-user-type';
import { v2Links } from '@utils/navigation/links';
import { TextLink, Typography } from '@v2/ui';
import { cn } from '@v2/utils';
import { SigninFlow } from './SigninLogic';

export function Signin() {
  const userType = useAuthUserType();
  return (
    <>
      <div>
        <Typography
          variant="h1"
          size={'dsm'}
          className={cn(
            'mb-3 text-center',
            userType === 'agency' && 'text-white'
          )}
        >
          {userType === 'agency'
            ? 'Partner login'
            : 'Sign in or create an account'}
        </Typography>
        {userType === 'user' && (
          <Typography
            size={'tmd'}
            className="text-center font-medium text-gray-500"
          >
            Your next trip is only a few clicks away.
          </Typography>
        )}{' '}
      </div>

      <SigninFlow />

      <Typography
        className={cn(
          userType === 'agency' ? 'text-gray-400' : 'text-gray-500'
        )}
        size="tsm"
      >
        By continuing, you confirm that you have read and agree to the{' '}
        <TextLink
          href={v2Links.termsAndCondition}
          className="font-semibold"
          legacyBehavior
          target="_blank"
        >
          Terms and Conditions
        </TextLink>{' '}
        and{' '}
        <TextLink
          href={v2Links.bookingTerms}
          className="font-semibold"
          legacyBehavior
          target="_blank"
        >
          Booking Conditions
        </TextLink>
        .
      </Typography>
    </>
  );
}
