'use client';

import {
  OrderReport,
  Report,
  UserReport,
} from '@components/dashboard/admin-reports';
import {
  DotsHorizontal,
  Download01,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuTrigger,
} from '@v2/ui';
import { useEffect, useState } from 'react';
const ReactApexChart = dynamic(() => import('react-apexcharts'), {
  ssr: false,
});

import dynamic from 'next/dynamic';
import './styles.css';

type CardChartProps = {
  title: string;
  triggerElement: React.ReactNode;
  children: React.ReactNode;
  list: { label: string; color: string }[];
};

export function CardChart(props: CardChartProps) {
  const { triggerElement, title, children, list } = props;
  return (
    <div
      className="flex w-full flex-col gap-y-4 rounded-xl border border-gray-200 p-4 pb-0 md:p-8"
      style={{ paddingBottom: 0 }}
    >
      <div className="flex items-center justify-between">
        <h3 className="text-md font-bold text-gray-800 lg:text-lg"> {title}</h3>

        <div>{triggerElement}</div>
      </div>
      <div className="flex items-center gap-x-4">
        {list.map((item) => (
          <div key={item.label} className="flex items-center gap-x-2">
            <div
              className="h-2 w-2 rounded-full"
              style={{ backgroundColor: item.color }}
            />
            <span className="text-xs font-normal text-gray-500">
              {item.label}
            </span>
          </div>
        ))}
      </div>

      {children}
    </div>
  );
}

export const ORDER_REPORTS_DATA = [
  { label: 'Orders', color: '#D0D5DD' },
  { label: 'Bookings', color: '#667085' },
  { label: 'Tickets', color: '#FA2846' },
];
const CHARTS_YEAR = [
  { label: 'Last year', color: '#344054' },
  { label: 'Current Year', color: '#FA2846' },
];

export function ReportBarChart(props: Report<OrderReport>) {
  const [ordersLineSeries, setOrdersLineSeries] = useState<any[]>([]);

  useEffect(() => {
    if (props.periodicReport) {
      setOrdersLineSeries([
        {
          name: 'Orders',
          data: [
            props.periodicReport.today.orders,
            props.periodicReport.thisMonth.orders,
            props.periodicReport.thisYear.orders,
          ],
          color: ORDER_REPORTS_DATA[0].color,
        },
        {
          name: 'Bookings',
          data: [
            props.periodicReport.today.bookings,
            props.periodicReport.thisMonth.bookings,
            props.periodicReport.thisYear.bookings,
          ],
          color: ORDER_REPORTS_DATA[1].color,
        },
        {
          name: 'Tickets',
          data: [
            props.periodicReport.today.tickets,
            props.periodicReport.thisMonth.tickets,
            props.periodicReport.thisYear.tickets,
          ],
          color: ORDER_REPORTS_DATA[2].color,
        },
      ]);
    }
  }, [props.periodicReport]);

  const state = {
    series: ordersLineSeries,
    options: {
      chart: {
        id: 'ordersChart',
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 6,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
          columnWidth: '90%',
          dataLabels: {
            position: 'top',
            maxItems: 100,
            hideOverflowingLabels: true,
            // orientation: horizontal,
            total: {
              enabled: false,
              formatter: undefined,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: '#373d3f',
                fontSize: '12px',
                fontFamily: undefined,
                fontWeight: 600,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['Today', 'This Month', 'This Year'],
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '18px',
            color: '#667085',
          },
        },
      },
      yaxis: {
        tickAmount: 4,
        labels: {
          formatter: function (val: number) {
            return val.toFixed(0); // Show labels as integers
          },
        },
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        style: {},
        y: {
          formatter: function (val: number) {
            return val.toString();
          },
        },
      },
    },
  };

  const downloadPNG = () => {
    const chart = ApexCharts.exec('ordersChart', 'dataURI');
    if (chart) {
      chart.then(({ imgURI }) => {
        const link = document.createElement('a');
        link.href = imgURI;
        link.download = 'chart.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  };

  const downloadCSV = () => {
    // Manually construct the CSV content
    const csvRows = [];
    const headers = ['Category', ...state.series.map((s) => s.name)];
    // @ts-ignore fix type later
    csvRows.push(headers.join(','));

    // Iterate over the categories and series data
    state.options.xaxis.categories.forEach((category, index) => {
      const row = [category];
      state.series.forEach((series) => {
        row.push(series.data[index]);
      });
      // @ts-ignore fix type later
      csvRows.push(row.join(','));
    });

    // Create a Blob from the CSV content
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'chart.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <CardChart
      list={ORDER_REPORTS_DATA}
      title="All Purchases"
      triggerElement={
        <>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <DotsHorizontal className="text-gray-500" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="min-w-[194px] bg-white" align="end">
              <DropdownMenuSub>
                <DropdownMenuItem
                  className="flex cursor-pointer items-center gap-3 hover:bg-gray-100"
                  onSelect={downloadPNG}
                >
                  <Download01 /> Download PNG
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="flex cursor-pointer items-center gap-3 hover:bg-gray-100"
                  onSelect={downloadCSV}
                >
                  <Download01 /> Download CSV
                </DropdownMenuItem>
              </DropdownMenuSub>
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      }
    >
      <ReactApexChart
        id="ordersChart"
        // @ts-ignore fix type later
        options={state.options}
        series={state.series}
        type="bar"
        height="200px"
        width={'100%'}
      />
    </CardChart>
  );
}

type LineChartProps = {
  type:
    | 'bookings'
    | 'orders'
    | 'tickets'
    | 'newUsers'
    | 'authenticatedVisits'
    | 'unauthenticatedVisits';
  label: string;
} & Report<OrderReport | UserReport>;

export function ReportLineChart(props: LineChartProps) {
  const [ordersLineSeries, setOrdersLineSeries] = useState<any[]>([]);

  const { type, yearlyReport, label } = props;

  const CHART_ID = `linechart-${type}`;

  useEffect(() => {
    if (yearlyReport) {
      const thisYearData =
        yearlyReport.thisYear?.map((r) => (r && r[type] ? r[type] : 0)) || [];
      const ols = [
        {
          color: '#FA2846',
          name: 'Current year',
          data: thisYearData,
        },
      ];

      if (yearlyReport.lastYear && yearlyReport.lastYear.length) {
        const lastYearData =
          yearlyReport.lastYear.map((r) => (r && r[type] ? r[type] : 0)) || [];
        ols.push({
          color: '#344054',
          name: 'Last year',
          data: lastYearData,
        });
      }

      setOrdersLineSeries(ols);
    }
  }, [yearlyReport, type]);

  function downloadPNG() {
    const chart = ApexCharts.exec(CHART_ID, 'dataURI');
    if (chart) {
      chart.then(({ imgURI }) => {
        const link = document.createElement('a');
        link.href = imgURI;
        link.download = 'chart.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }

  function downloadCSV() {
    const state = getLineOptions([
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]);

    const csvRows = [];
    const headers = ['Category', ...ordersLineSeries.map((s) => s.name)];
    // @ts-ignore fix type later
    csvRows.push(headers.join(','));

    // Iterate over the categories and series data
    state.xaxis.categories.forEach((category, index) => {
      const row = [category];
      ordersLineSeries.forEach((series) => {
        row.push(series.data[index]);
      });
      // @ts-ignore fix type later
      csvRows.push(row.join(','));
    });

    // Create a Blob from the CSV content
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'chart.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const getLineOptions = (labels) => {
    return {
      title: {
        align: 'left',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#263238',
        },
      },
      dataLabels: {
        enabled: false,
      },
      chart: {
        id: CHART_ID,
        stacked: true,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: labels,
        labels: {
          style: {
            fontSize: '12px',
          },
          rotate: -45,
          hideOverlappingLabels: true,
        },
      },
      yaxis: {
        tickAmount: 5,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          gradientToColors: [
            // 'rgba(52, 64, 84, 0.96)',
            // 'rgba(250, 40, 70, 0.00)'
          ],
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0.12,
          stops: [0, 100],
        },
      },
    };
  };

  return (
    <CardChart
      list={CHARTS_YEAR}
      title={label}
      triggerElement={
        <>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <DotsHorizontal className="text-gray-500" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="min-w-[194px] bg-white" align="end">
              <DropdownMenuSub>
                <DropdownMenuItem
                  className="flex cursor-pointer items-center gap-3 hover:bg-gray-100"
                  onSelect={downloadPNG}
                >
                  <Download01 /> Download PNG
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="flex cursor-pointer items-center gap-3 hover:bg-gray-100"
                  onSelect={downloadCSV}
                >
                  <Download01 /> Download CSV
                </DropdownMenuItem>
              </DropdownMenuSub>
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      }
    >
      <ReactApexChart
        // @ts-ignore
        options={getLineOptions([
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ])}
        series={ordersLineSeries}
        type="area"
        height="200px"
        width={'100%'}
      />
    </CardChart>
  );
}
