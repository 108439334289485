import { LoggedInUserProps } from '@interfaces/auth';
import { authConfig } from '@pages/api/auth/[...nextauth]';
import { getServerSession } from 'next-auth/next';

const getUserSession = async () => {
  const session = await getServerSession(authConfig);
  const user = session?.user as LoggedInUserProps;

  if (user?.token?.accessToken) {
    return user;
  }

  return null;
};

export default getUserSession;
