import { Guests, RoomConfiguration } from '@components/RoomConfigurator';
import { errorToast } from '@utils/toasts';
import { getInternationalTimeShort, getInternationalYear } from './dates';

export const getDate = (startDate, endDate) => {
  if (!startDate && !endDate) return null;

  const startTime = startDate && getInternationalTimeShort(startDate);
  const endTime = endDate && startDate && getInternationalTimeShort(endDate);
  const startDateRes = startDate && getInternationalYear(startDate);
  const endDateRes = endDate && startDate && getInternationalYear(endDate);

  if (!endTime && !endDateRes) {
    return `${startTime}, ${startDateRes}`;
  }

  return `${startTime}${startDateRes === endDateRes ? '' : startDateRes} - ${endTime}, ${endDateRes}`;
};

export const getGuests = (
  roomConfigurations: RoomConfiguration[],
  withAges: boolean = true
): Guests => {
  let rooms = 0;
  let adults = 0;
  let children = 0;
  const childrenAges: number[] = [];
  let infants = 0;

  roomConfigurations.forEach((roomConfiguration) => {
    rooms += 1;
    adults += roomConfiguration.adults;

    roomConfiguration.children.forEach((child) => {
      if (withAges && child === 0) return (infants += 1);

      children += 1;
      childrenAges.push(child);
    });

    infants += roomConfiguration.infants;
  });

  return {
    rooms,
    adults,
    children,
    childrenAges,
    infants,
  };
};

export const parseOccupancy = (occupancy: string): RoomConfiguration => {
  const splitGuests = occupancy.split('-');
  const adults = parseInt(splitGuests[0], 10);
  let children: number[] = [];

  if (splitGuests[1]) {
    children = splitGuests[1].split(',').map((child) => parseInt(child, 10));
  }

  return { adults, children, infants: 0 };
};

export const getGuestsString = (
  roomConfigurations: RoomConfiguration[],
  withRooms = true,
  withAges = false
): string | null => {
  if (!roomConfigurations || !roomConfigurations.length) return '';

  const { rooms, adults, children, childrenAges, infants } = getGuests(
    roomConfigurations,
    withAges
  );

  if (!(rooms || adults || children || infants)) return null;

  const res = [] as string[];

  if (adults) res.push(`${adults} ${adults > 1 ? 'Adults' : 'Adult'}`);
  if (children) {
    res.push(
      `${children} ${children > 1 ? 'Children' : 'Child'}${withAges && childrenAges.length ? ` (age${childrenAges.length > 1 ? 's' : ''} ${childrenAges.join(', ')})` : ''}`
    );
  }

  if (infants) {
    res.push(`${infants} ${infants > 1 ? 'Babies' : 'Baby'}`);
  }

  if (rooms && withRooms) res.push(`${rooms} ${rooms > 1 ? 'Rooms' : 'Room'}`);

  return res.length ? res.join(', ') : null;
};

export const getHotelGuestsCount = (
  roomConfigurations: RoomConfiguration[]
): number => {
  if (!roomConfigurations || !roomConfigurations.length) return 0;

  const { rooms, adults, children, infants } = getGuests(roomConfigurations);

  if (!(rooms || adults || children || infants)) return 0;

  let res = 0;

  if (adults) res += adults;
  if (children) res += children;
  if (infants) res += infants;

  return res;
};

export const errorShow = (message: any) => {
  if (typeof message === 'string') {
    errorToast(message);
  } else if (Array.isArray(message)) {
    message.forEach((item) => errorShow(item));
  } else if (typeof message === 'object') {
    Object.values(message).forEach((item) => errorShow(item));
  } else {
    errorToast('Something went wrong.');
  }
};

export const buildHotelFullLocation = (
  location: string,
  city?: string,
  state?: string,
  postalCode?: string,
  country?: string
): string => {
  let fullLocation = location;

  if (city) {
    fullLocation = fullLocation.concat(', ', city);
  }

  if (state) {
    fullLocation = fullLocation.concat(', ', state);
  }

  if (postalCode) {
    fullLocation = fullLocation.concat(' ', postalCode);
  }

  if (country) {
    fullLocation = fullLocation.concat(', ', country);
  }

  return fullLocation;
};
