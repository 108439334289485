import { LoggedInUserProps } from '@interfaces/auth';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  typographyVariants,
} from '@v2/ui';
import { cn } from '@v2/utils';
import React from 'react';

type Props = {
  user: LoggedInUserProps;
  variant?: 'white' | 'black';
  className?: string;
};

export function UserAvatar({ user, variant = 'white', className }: Props) {
  const userLastNameChar =
    user?.lastName && user?.lastName?.length > 0 ? user.lastName[0] : '';
  return (
    <Avatar
      className={cn(
        variant === 'white' && 'bg-[#98A2B329] backdrop-blur-sm',
        variant === 'black' && 'border border-gray-200',
        className
      )}
    >
      <AvatarImage src={user.imageUrl} className="object-cover" />

      <AvatarFallback
        className={typographyVariants({
          size: 'tmd',
          className: `font-semibold uppercase ${variant === 'black' ? 'text-gray-800' : 'text-white'}`,
        })}
      >
        {`${user.firstName[0]}${userLastNameChar}`}
      </AvatarFallback>
    </Avatar>
  );
}
