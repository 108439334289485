import { Column } from '@tanstack/react-table';
import { Button } from '@v2/ui';
import React from 'react';
import SortIndicator from '../SortIndicator/SortIndicator';

type Props<TData> = {
  onClick: () => void;
  children: React.ReactNode;
  column: Column<TData, unknown>;
};

/*
 *  This component is best utilized with Table And Tanstack Table Header
 */
export function DataTableHeader<TData>({
  column,
  children,
  onClick,
}: Props<TData>) {
  return (
    <Button
      variant="ghost"
      className="justify-start p-0 text-xs font-medium"
      onClick={onClick}
    >
      {children}
      <SortIndicator
        isSorted={Boolean(column.getIsSorted())}
        isSortedDesc={column.getIsSorted() === 'desc'}
      />
    </Button>
  );
}
