import type { LoggedInUserProps } from '@interfaces/auth';
import { USER_ROLE } from '@utils/auth/roles';
import { links, v2Links } from '@utils/navigation/links';
import {
  Backpack,
  BookOpen01,
  Building05,
  CoinsHand,
  CoinsStacked01,
  CreditCard02,
  Star01,
  User01,
  UserCheck01,
  Users01,
  type SVGComponentProps,
} from '@v2/ui';
import { BarChart10, LineChartUp02, LineChartUp04 } from '@v2/ui/Icon/Charts';
import { useEffect, useState, type FC } from 'react';

interface DashboardSection {
  title: string | null;
  mobileTitle: string;
  items: DashboardRoute[];
}

export interface DashboardRoute {
  title: string;
  path: string;
  roles: USER_ROLE[];
  childs?: string[];
  icon: FC<SVGComponentProps>;
}

export const V2_DASHBOARD_LINKS: DashboardSection[] = [
  {
    title: null,
    mobileTitle: 'Settings',
    items: [
      {
        icon: Star01,
        path: v2Links.favorites,
        title: 'Favorites',
        roles: [
          USER_ROLE.user,
          USER_ROLE.admin,
          USER_ROLE.superAdmin,
          USER_ROLE.affiliate,
        ],
      },
      {
        icon: CreditCard02,
        path: v2Links.orders,
        title: 'Orders',
        roles: [
          USER_ROLE.user,
          USER_ROLE.admin,
          USER_ROLE.superAdmin,
          USER_ROLE.affiliate,
        ],
      },
      {
        title: 'Rewards',
        icon: CoinsStacked01,
        path: v2Links.rewards,

        roles: [
          USER_ROLE.user,
          USER_ROLE.admin,
          USER_ROLE.superAdmin,
          USER_ROLE.affiliate,
        ],
      },
      {
        icon: User01,
        title: 'Personal info',
        path: v2Links.profile,

        roles: [
          USER_ROLE.user,
          USER_ROLE.admin,
          USER_ROLE.superAdmin,
          USER_ROLE.affiliate,
        ],
      },

      {
        icon: Backpack,
        title: 'Persons',
        path: v2Links.travelers,

        roles: [
          USER_ROLE.user,
          USER_ROLE.admin,
          USER_ROLE.superAdmin,
          USER_ROLE.affiliate,
        ],
      },

      // {
      //   icon: Bell02,
      //   title: 'Notifications',
      //   path: v2Links.notifications,

      //   roles: [
      //     USER_ROLE.user,
      //     USER_ROLE.admin,
      //     USER_ROLE.superAdmin,
      //     USER_ROLE.affiliate,
      //   ],
      // },
    ],
  },

  {
    title: 'PARTNER',
    mobileTitle: 'Partner',
    items: [
      {
        title: 'Overview',
        path: v2Links.partnersOverview,
        roles: [USER_ROLE.agency, USER_ROLE.agencyMember],
        icon: LineChartUp04,
      },
      {
        title: 'Reports',
        path: v2Links.partnerReports,
        roles: [USER_ROLE.agency, USER_ROLE.agencyMember],
        icon: BarChart10,
      },
      {
        title: 'My team',
        path: v2Links.partnerTeams,
        roles: [USER_ROLE.agency],
        icon: Users01,
      },
      {
        title: 'Performance',
        path: v2Links.partnersPerformance,
        roles: [USER_ROLE.agency],
        icon: LineChartUp02,
      },
      {
        title: 'Sales',
        path: v2Links.partnerSales,
        roles: [USER_ROLE.agency, USER_ROLE.agencyMember],
        icon: CoinsHand,
      },
      {
        title: 'Partner rewards',
        path: v2Links.partnerPromoCodes,
        roles: [USER_ROLE.agency],
        icon: CoinsStacked01,
      },
    ],
  },
  {
    title: 'AFFILIATE',
    mobileTitle: 'Affiliate',
    items: [
      {
        icon: LineChartUp04,
        title: 'Overview',
        path: v2Links.affiliateGeneral,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin, USER_ROLE.affiliate],
      },
      {
        icon: LineChartUp02,
        title: 'Performance',
        path: v2Links.affiliatePerformance,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin, USER_ROLE.affiliate],
      },
      {
        icon: CoinsHand,
        title: 'Sales',
        path: v2Links.affiliateSales,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin, USER_ROLE.affiliate],
      },
      {
        icon: CoinsStacked01,
        title: 'Affiliate rewards',
        path: v2Links.affiliatePromoCodes,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin, USER_ROLE.affiliate],
      },
    ],
  },
  {
    title: 'AGENT',
    mobileTitle: 'Agent',
    items: [
      {
        icon: LineChartUp04,
        title: 'Overview',
        path: v2Links.affiliateGeneral,
        roles: [USER_ROLE.individualAgent],
      },
      {
        icon: LineChartUp02,
        title: 'Performance',
        path: v2Links.affiliatePerformance,
        roles: [USER_ROLE.individualAgent],
      },
      {
        icon: CoinsHand,
        title: 'Sales',
        path: v2Links.affiliateSales,
        roles: [USER_ROLE.individualAgent],
      },
      {
        icon: CoinsStacked01,
        title: 'Agent rewards',
        path: v2Links.affiliatePromoCodes,
        roles: [USER_ROLE.individualAgent],
      },
    ],
  },
  {
    title: 'ADMIN',
    mobileTitle: 'Admin',
    items: [
      {
        icon: BarChart10,
        title: 'Reports',
        path: v2Links.adminReports,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin],
      },
      {
        icon: CreditCard02,
        title: 'Orders',
        path: v2Links.adminOrders,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin],
      },
      {
        icon: CoinsStacked01,
        title: 'Elite Cash & Rewards',
        path: v2Links.adminDiscountCodes,
        roles: [USER_ROLE.superAdmin],
        childs: [v2Links.dashboardPage('discount-code-details/:id')],
      },
      {
        icon: Users01,
        title: 'Users',
        path: v2Links.adminUsers,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin],
        childs: [v2Links.dashboardPage('user-details/:id')],
      },
      {
        icon: Building05,
        title: 'Agencies',
        path: links.adminAgencies,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin],
      },
      {
        icon: UserCheck01,
        title: 'Partners',
        path: v2Links.adminPartners,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin],
      },

      {
        icon: BookOpen01,
        title: 'Cache Logs',
        path: v2Links.adminCacheLogs,
        roles: [USER_ROLE.admin, USER_ROLE.superAdmin],
      },
    ],
  },
];

export function useDashboardLinks(props: LoggedInUserProps | null) {
  const role = props?.role;

  const userRole = role || role === 0 ? role : USER_ROLE.user;
  const [allowedSections, setAllowedSections] = useState<DashboardSection[]>(
    []
  );

  useEffect(() => {
    const filteredSections: DashboardSection[] = [];

    V2_DASHBOARD_LINKS.forEach((section) => {
      const filteredItems: DashboardRoute[] = [];

      section.items.forEach((item) => {
        if (item.roles.includes(userRole)) {
          filteredItems.push(item);
        }
      });

      if (filteredItems.length > 0) {
        filteredSections.push({
          title: section.title,
          items: filteredItems,
          mobileTitle: section.mobileTitle,
        });
      }
    });

    setAllowedSections(filteredSections);
  }, [userRole]);

  return allowedSections;
}
