'use client';
import tico from '@assets/tico.jpg';
import { v2Links } from '@utils/navigation/links';
import { Typography } from '@v2/ui';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useSelectedLayoutSegment } from 'next/navigation';
import { PropsWithChildren } from 'react';

const socialItems = [
  {
    id: 1,
    name: 'instagram',
    icon: '/icons/instagram-fill.svg',
    url: 'https://www.instagram.com/elitesportstours/',
  },
  {
    id: 2,
    name: 'youtube',
    icon: '/icons/youtube-fill.svg',
    url: 'https://www.youtube.com/user/EliteSportsTours',
  },
  {
    id: 3,
    name: 'facebook',
    icon: '/icons/facebook-fill.svg',
    url: 'https://www.facebook.com/elitesportstours',
  },
  {
    id: 4,
    name: 'twitter',
    icon: '/icons/x-fill.svg',
    url: 'https://twitter.com/elitesportstour',
  },
];

const companyPages = [
  {
    name: 'Home',
    url: v2Links.home,
  },
  {
    name: 'About us',
    url: v2Links.aboutUs,
  },
  {
    name: 'Blog',
    url: 'https://blog.elitesportstours.com/',
  },
  {
    name: 'FAQ',
    url: v2Links.faq,
  },
  {
    name: 'Contact Us',
    url: v2Links.contactUs,
  },
];

const trendingCategories = [
  { title: 'MLB Baseball', url: '/events/mlb' },
  { title: 'NBA Basketball', url: '/events/nba' },
  { title: 'NFL Football', url: '/events/nfl' },
  { title: 'NHL Hockey', url: '/events/nhl' },
  { title: 'Nascar', url: '/events/nascar' },
];

const HIDE_FOOTER_ON_ROUTES = [
  'auth',
  'finalize-account',
  '(buildPackage)',
  'checkout',
];

export const Footer = ({ children }: PropsWithChildren) => {
  const segment = useSelectedLayoutSegment();
  const pathname = usePathname();
  if (segment && HIDE_FOOTER_ON_ROUTES.includes(segment)) return null;

  return (
    <div className="w-full px-4 pt-10 md:pt-20 xl:px-0">
      <div className="mx-auto my-0 flex w-full max-w-7xl flex-col justify-between border-b border-gray-200 pb-6 sm:flex-row md:pb-20">
        <div>
          <Image
            src="/logo-footer.svg"
            alt="Elite Sports Tours"
            className="mb-6 md:mb-10"
            width={200}
            height={40}
          />
          <h2 className="mb-3 text-sm font-medium">{`Let's connect`}</h2>
          <div className="mb-6 flex items-center gap-3 md:mb-0">
            {socialItems.map((item) => (
              <a
                href={item.url}
                key={item.id}
                aria-label={`Elite Sports Tours ${item.name}`}
              >
                <Image
                  src={item.icon}
                  alt={`Elite Sports Tours ${item.name}`}
                  width={24}
                  height={24}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="flex w-full flex-row flex-wrap gap-y-6 md:max-w-fit md:gap-12">
          <div className="w-full max-w-40 md:max-w-fit">
            <h3 className="mb-5 text-base font-semibold text-gray-800">
              Company
            </h3>
            <ul>
              {companyPages.map((page) => (
                <li
                  key={page.name}
                  className="mb-3 text-sm leading-5 text-slate-500"
                >
                  <a className="" href={page.url}>
                    {page.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full max-w-40 md:max-w-fit">
            <h3 className="mb-5 text-base font-semibold text-gray-800">
              Trending Categories
            </h3>
            <ul>
              {trendingCategories.map((page) => (
                <li
                  key={page.title}
                  className="mb-3 text-sm leading-5 text-slate-500"
                >
                  <a href={page.url}>{page.title}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="hidden w-full md:block md:max-w-fit">{children}</div>
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-7xl flex-col justify-between py-6 md:flex-row">
        <div className="mb-6 flex flex-col items-start gap-x-8 gap-y-6 md:mb-0 md:flex-row md:items-center">
          <span className="text-sm leading-5 text-gray-500">
            Copyright © {new Date().getFullYear()} - by Elite Sports Tours Inc.
            <div className="text-sm text-gray-500">{`Build Number: ${process.env.NEXT_PUBLIC_BUILD_VERSION}`}</div>
          </span>
          <div className="flex items-center md:mr-6">
            <Image src={tico} alt="tico" width={42.48} height={24} />
            <Typography size="tsm" className="ml-2.5 font-medium text-gray-500">
              TICO #50017473
            </Typography>
          </div>
        </div>
        <ul className="mb-6 flex flex-col gap-2 md:mb-0 md:flex-row md:gap-8">
          <li className="text-sm leading-5 text-gray-500">
            <a href={v2Links.bookingTerms}>Booking Conditions</a>
          </li>
          <li className="text-sm leading-5 text-gray-500">
            <Link href={v2Links.termsAndCondition} prefetch={false}>
              Terms & Conditions
            </Link>
          </li>
          <li className="text-sm leading-5 text-gray-500">
            <Link href={v2Links.privacyPolicy} prefetch={false}>
              Privacy Policy
            </Link>
          </li>
          <li className="text-sm leading-5 text-gray-500">
            <Link href="/sitemap" prefetch={false}>
              Sitemap
            </Link>
          </li>
          {pathname === '/sports-travel-agent-network' && (
            <li className="text-sm leading-5 text-gray-500">
              <Link href={v2Links.partnerTerms} prefetch={false}>
                Partner terms
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
