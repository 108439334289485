'use client';
import { CAD_CURRENCY, USD_CURRENCY } from '@utils/currency';
import {
  ChevronDown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuTrigger,
} from '@v2/ui';
import { cn } from '@v2/utils';
import Image from 'next/image';
import { useCurrencyDropdown } from './use-currency-dropdown';

const items = {
  [USD_CURRENCY]: {
    name: 'US dollar',
    slug: 'USD',
    flag: '/flags/usa.webp',
  },
  [CAD_CURRENCY]: {
    name: 'Canadian dollar',
    slug: 'CAD',
    flag: '/flags/canada.webp',
  },
};

interface Props {
  variant: 'black' | 'white';
  className?: string;
}

export const CurrencyDropdown = ({ variant, className }: Props) => {
  const [selectedCurrency, setSelectedCurrency] = useCurrencyDropdown();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          `flex flex-row items-center gap-2 text-${variant} mr-4 px-4 py-3`,
          className
        )}
      >
        <Image
          src={items[selectedCurrency].flag}
          width={20}
          height={20}
          alt={items[selectedCurrency].name}
        />
        <h4 className="text-base font-semibold">
          {items[selectedCurrency].slug}
        </h4>
        <ChevronDown size="20" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[194px] bg-white" align="end">
        <DropdownMenuSub>
          {Object.keys(items).map((key) => (
            <DropdownMenuItem
              key={key}
              className="flex cursor-pointer gap-3 hover:bg-gray-100"
              onSelect={() => {
                setSelectedCurrency(key);
              }}
            >
              <Image
                src={items[key].flag}
                width={20}
                height={20}
                alt={items[key].name}
              />
              <h4 className="text-base font-semibold">
                {items[key].name}{' '}
                <span className="text-gray-500">({items[key].slug})</span>
              </h4>
            </DropdownMenuItem>
          ))}
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
