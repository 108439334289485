import { toast, ToastOptions } from 'react-hot-toast';

const options: ToastOptions = {
  position: 'top-right',
};

export const errorToast = (message: string) => {
  toast.error(message, options);
};

export const successToast = (message: string) => {
  toast.success(message, options);
};
