'use client';
import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { Table } from '@tanstack/react-table';
import { ArrowLeft, ArrowRight, Button } from '@v2/ui';

interface PaginationProps<TData> {
  table: Table<TData>;
}

export const DataTablePagination = <TData,>({
  table,
}: PaginationProps<TData>) => {
  const isTablet = useBetterMediaQuery('(min-width: 768px)');

  const maxVisiblePages = 3; // Maximum number of pagination buttons to show

  const renderPageButtons = () => {
    const pageCount = table.getPageCount();
    const { pageIndex } = table.getState().pagination;

    const pages: (number | 'ellipsis')[] = [];

    if (pageCount <= maxVisiblePages) {
      // If total pages are less than or equal to maxVisiblePages, show all pages
      for (let i = 0; i < pageCount; i++) {
        pages.push(i);
      }
    } else {
      // If total pages are more than maxVisiblePages, show a subset of pages
      const startPage = Math.max(
        0,
        pageIndex - Math.floor(maxVisiblePages / 2)
      );
      const endPage = Math.min(pageCount - 1, startPage + maxVisiblePages - 1);

      if (startPage > 0) {
        pages.push(0);
        if (startPage > 1) {
          pages.push('ellipsis');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < pageCount - 1) {
        if (endPage < pageCount - 2) {
          pages.push('ellipsis');
        }
        pages.push(pageCount - 1);
      }
    }

    return pages.map((page, index) => {
      if (page === 'ellipsis') {
        return (
          <span key={index} className="p-3 text-xs font-medium text-gray-500">
            ...
          </span>
        );
      }

      return (
        <Button
          key={index}
          size="md"
          variant="ghost"
          className={
            pageIndex === page
              ? 'border border-gray-200 bg-gray-50 text-gray-800'
              : 'text-gray-500'
          }
          onClick={() => table.setPageIndex(page)}
        >
          {page + 1}
        </Button>
      );
    });
  };

  if (table.getPageCount() <= 1) return null;

  return (
    <div className="mx-auto mt-2 flex items-center justify-between text-gray-800 md:mx-0 md:mt-4">
      {isTablet && (
        <Button
          size="md"
          variant="ghost"
          className="gap-x-2"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ArrowLeft size="20" pathProps={{ stroke: 'currentColor' }} />
          Previous
        </Button>
      )}
      <div className="flex items-center gap-x-2">{renderPageButtons()}</div>
      {isTablet && (
        <Button
          size="md"
          variant="ghost"
          className="gap-x-2"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
          <ArrowRight size="20" pathProps={{ stroke: 'currentColor' }} />
        </Button>
      )}
    </div>
  );
};
