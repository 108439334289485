'use client';
import { LogoLoader } from '@v2/ui';

export function PageLoader() {
  return (
    <div className="flex min-h-screen w-screen items-center justify-center bg-gray-50">
      <div className="flex flex-col items-center justify-center">
        <LogoLoader
          style={{
            width: '93px',
            height: '88px',
            transform: 'scale(3)',
          }}
        />

        <p className="text-md mt-16 font-medium text-gray-800">
          Searching for the best deals from our partners.
        </p>
      </div>
    </div>
  );
}
