'use client';
import { EmblaOptionsType } from 'embla-carousel';

import { ChevronLeft, ChevronRight } from '@v2/ui';
import useEmblaCarousel from 'embla-carousel-react';
import { usePrevNextButtons } from './ArrowButtons';

type Props = {
  options?: EmblaOptionsType;
  children: React.ReactNode;
};

export const Carousel = ({
  options = { slidesToScroll: 'auto' },
  children,
}: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <section className="embla w-full">
      <div className="relative">
        <div className="embla__viewport overflow-hidden" ref={emblaRef}>
          <div className="embla__container flex flex-row gap-6">{children}</div>
        </div>

        <div className="absolute top-16 flex h-full min-h-fit">
          <button
            className="flex h-12 w-12 items-center justify-center rounded-full bg-white text-gray-800 shadow-md disabled:hidden lg:ml-[-20px]"
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
          >
            <ChevronLeft />
          </button>
        </div>

        <div className="absolute right-0 top-16 flex h-full min-h-fit">
          <button
            className="flex h-12 w-12 items-center justify-center rounded-full bg-white text-gray-800 shadow-md disabled:opacity-50 lg:mr-[-20px]"
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
          >
            <ChevronRight />
          </button>
        </div>
      </div>
    </section>
  );
};
